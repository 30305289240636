//misc tessitura pages. We may break this stylesheet apart if needed.

.page-tessitura-embed {
    color: $gray-text-dark;
    font-family: $font-sans;
    font-weight: $font-regular;
    background-color: $white; //the center bg should be white (we change header/footer/etc

    .alert-bar {
        margin-bottom: 0;

    }

    .main-header {
        border: 0;
        .header-ads {
            background-color: $white;
        }
    }

    .global-sponsors {
        border: 0;
        background-color: $white-off;
        margin-bottom: 0;

    }

    // match grid
    .container {
        @include lg {
            width: 1400px;
        }
    }

    #root+.container {
        @include xs {
            padding-left: 30px;
            padding-right: 30px;
        }
    }




        // primary theme color overrides
        .btn-primary,
        .btn-primary[type="submit"],
        .btn-info,
        .tn-btn-datepicker .btn-primary
         {
            background-color: $brand-primary;
            &:hover, &:active, &:focus {
                background-color: darken($brand-primary, 10%);
            }
        }

        // link color override
        .tn-account-login-page .tn-account-login-forgot-login-link, .tn-detail-page-header .tn-detail-production-text-toggle-link, .tn-gift-certificate-component #apply-gc-submit, .tn-gift-certificate-component .tn-apply.tn-payment-gift-certificate-toggle, .tn-gift-certificate-component .tn-cancel.tn-payment-gift-certificate-toggle, .tn-listing-filter-component .tn-date-filter-reset-button, .tn-listing-legend-toggle-link, .tn-payment-plan-component .tn-apply.tn-payment-plan-toggle, .tn-payment-plan-component .tn-cancel.tn-payment-plan-toggle, .tn-payment-plan .tn-apply.tn-payment-plan-toggle, .tn-payment-plan .tn-cancel.tn-payment-plan-toggle, .tn-subnav-component .tn-promo-box button.tn-subnav-promo-button, .tnew_button_as_link, .tn-btn-datepicker__icon-container .glyphicon {
            color: $brand-primary;
        }

    .tn-event-listing-mode-tab-nav__list-item, .tn-events-list-view .tn-prod-list-item__property a, .tn-link, .tn-additional-events__continue-shopping-link, .tn-event-detail__performance-details-container a, .tn-cart-buttons__secondary-action, .tn-heading-sub-text a {
        color: $brand-primary;
    }

    .tn-events-calendar__day-event-list-item {
        @include xs {
            max-width: 480px;
            margin: 15px auto;
        }
    }

    .tn-events-calendar__table {
        .tn-events-calendar__event-name, .tn-events-calendar__event-time  {
            font-weight: $font-semibold;
        }
    }

    .tn-btn-datepicker {
        .btn-primary[disabled] {
            opacity: 1;
        }
    }

    .tn-event-detail {
        a {
            color: $brand-primary;
            &:hover, &:active, &:focus {
                color: darken($brand-primary, 10%);
            }
        }

        .tn-syos-screen-button {
            background-color: $brand-primary;
            &:hover, &:active, &:focus {
                background-color: darken($brand-primary, 10%);
            }
        }
    }

    .tn-syos-seat-map__stage-indicator-label {
        color: $black;
    }

    .main-footer {
        background-color: $white-off;
        margin-top: 0;

        .footer-nav-main  {
            a {
                color: inherit;
            }
        }
    }
}


.tn-events-listing-page {



}
