//a teaser gallery is a simple image with text overlayed and a popup link on the top left that opens
//the gallery. (used in home and Photo Gallery daily posts)

//TODO: separate styles to .daily-teaser with .teaser-gallery / .teaser-video for differing styles
.teaser-gallery, .teaser-video {
    position: relative;
    &, a {
        color: $white;
    }

    //the popup link
    &:hover {
        .video-lightbox {
            opacity: 1;
        }
    }

    .icon-link {
        position: absolute;
        top: 0;
        right: 0;
        font-size: 18px;
    }

    .image-gallery-popup, .video-lightbox { //the expander icon on the top left
        position: absolute;
        top: 10px;
        left: 10px;
        z-index: 5;
        @include md-and-up { //controls are shown on hover in desktop
            opacity: 0;
        }
        i {
            font-size: 30px;
            opacity: 0.7;
        }

        &:hover {
            text-decoration: none;
            i {
                opacity: 1;
            }
        }

        @include sm-and-up {
            i {
                font-size: 15px;
            }
        }

    }

    //text content and popup only show on hover
    .gallery-item-controls {
        padding: 15px;
        transition: opacity $transition-link-hover;
        @include sm-and-up {
            padding: 5px;
        }
        @include md-and-up { //controls are shown on hover in desktop
            opacity: 0;
        }

    }
    .item-text {
        position: absolute;
        bottom: 30px;
        width: 100%;
        padding: 0 10px 0;
    }
    h2 {
        width: 75%;
        margin: 0 auto;
    }
    time {
        width: 75%;
        font-size: 13px;
        line-height: 16px;
        margin: 0 auto;
    }
    &:hover {
        .gallery-item-controls, .image-gallery-popup {
            opacity: 1;
        }
        .gallery-item-controls {
            box-shadow: inset 0 0 0 2000px rgba(0, 0, 0, .2);
        }
    }


    //spacing between items
    margin-bottom: 30px;

    h2 {
        margin-bottom:5px;
        text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.7);
        font-size: 14px;
        line-height: 1.2;
    }
    time {
        font-size: 12px;
    }

    @media (min-width: $screen-xs) and (max-width: $screen-sm - 1px) {
        h2 {
            font-size: 20px;
        }
    }
    @include sm {
        h2 {
            font-size: 14px;
        }
    }

    @include md {
        h2 {
            font-size: 14px;
        }
    }

    @include md-and-up {
        h2 {
            font-size: 18px;
        }
        time {
            font-size: 13px;
        }
    }

} //.teaser-gallery
