.rolex-embed {
    background-color: #212121;
    justify-content: center;
    align-items: center;
    overflow: hidden;

    &--mobile {
        display: flex;
        margin-bottom: 20px;

        @include sm-and-up {
            display: none;
            margin-bottom: 0;
        }
    }
    &--desktop {
        display: none;
        @include sm-and-up {
            display: flex;
            margin-bottom: 20px;
        }
    }
}