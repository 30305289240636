//== Buttons

.btn {
    border-radius: 0;
    &:focus {
        background: inherit;
    }
}

//== Links
a {
    transition: $transition-link;

    &:hover {
        transition: $transition-link-hover;
    }
}

//if the link has this class don't behave like a link
.link-subtle { //link has the color of text
    color: inherit; //blend in
    &:hover {
        text-decoration: none; //don't underline
        color: inherit; //blend in
    }
}
.link-not-available {
    @extend .link-subtle;
    cursor: none; //don't point
}



.arrow {
    font-weight: bold;
}

.btn,
.cta {
    @extend %font-headline;
}

.btn-orange {
    text-transform: none;
    background-color: $violet;
    color: $white;
    opacity: 0.95;
    font-family: $font-sans;
    font-weight: $font-bold;

    i { //should they come with an icon on the right. some stgarter styles (adjust as needed)
        margin-right: 5px;

        //horizontally alighn with text
        position: relative;
        top: 1px;
    }

    &:hover {
        //maintain color, just heighten brightness
        color: $white;
        background-color: $violet;
        opacity: 1;

    }

}

.btn-primary {
    &.btn-inverse {
        color: $brand-primary;
        background-color: $white;
        &:hover {
            background-color: $brand-primary;
            color: $white;
        }
    }
}

.cta {
    font-size: 16px;
    font-weight: $font-bold;

    &.arrow {
        i {
            font-size: 0.8em; //align with link text
            display: inline-block;
        }
    }
}
.rotator .play-btn,
.play-btn-basic,
body .video {
    color: $white;
    font-size: 115px;
    opacity: 0.8;
    position: absolute;
    width: 100%;
    z-index: 90;
    top: 50%;
    left: 0;
    -webkit-transform: translate(0,-50%);
    -ms-transform: translate(0,-50%);
    transform: translate(0,-50%);
    text-align: center;
    &:hover, &:focus {
        opacity: 1;
        text-decoration: none;
        outline: 0;
    }
    i {
        color: $white;
        position: relative;
        z-index: 1000;
    }
}

.video-lightbox {
    &:hover {
        text-decoration: none;
    }
}

//rotate font awesom. add .spinner class  to <i>
@keyframes anim-rotate {
	0% {
		transform: rotate(0);
	}
	100% {
		transform: rotate(360deg);
	}
}
.spinner {
	display: inline-block;
	animation: anim-rotate 2s infinite linear;
}
.spinner--steps {
	animation: anim-rotate 1s infinite steps(8);
}
.spinner--steps2 {
	animation: anim-rotate 1s infinite steps(12);
}
