//when needing
//should not be on same level bootstrap col  (can be child of it)
.feature-with-overlay {

    display: block;
    position: relative;

	@include md-and-up {
	    .feature-overlay {
	        position: absolute;
	        width: 100%;
	        // box-shadow: inset 0 0 0 2000px rgba(0, 0, 0, .2);
	        &.bottom {
	            bottom: 0;
	            background: $white;
	            @include sm-and-up {
	        		background: transparent;
		        }
	        }
	    }
	}
	@include sm {
		.bottom {
			padding: 20px;
		}
	}
	@include xs {
		.bottom {
			padding: 15px;
		}
	}
}


.film-series {
	.feature-with-overlay {
		// max-height: 300px;
		overflow: hidden;
		margin-bottom: 40px;

        h2 {
            a {
                color: inherit;
            }
        }

		@include sm-and-up {
			background: $black;
			img {
				width: 100%;
				opacity: 0.7;
			}
			.lead {
	            color: #bbb ;
	            font-size: 14px;
	            font-weight: $font-bold;
	            line-height: 1.2;
	            margin-bottom: 0;
	            letter-spacing: 0.1em;
	        }

	        h2 {
	            color: $white;
	            font-size: 34px;
	            line-height: 1.2;
	            margin-bottom: 0px;
	            margin-top: 0;
                a {
                    color: inherit;
                }
	        }

	        .summary, .summary p {
	            color: #ececec;
	            font-size: 16px;
	            line-height: 1.4;
	        }

	        .arrow {
	            color: $white;
	            font-size: 22px;

	        }

	        .text-content {
	            // padding-left: 20px;
	            position: absolute;
	            bottom: 20px;
	            left: 0px;
	            z-index: 2;
	        }

	        .feature-with-overlay .feature-overlay.bottom {
	            bottom: 10px;
	        }
	        .cta-col {
	        	position: absolute;
	        	bottom: 20px;
	        	right: 0px;
	        	text-align: right;
	        	color: $white;
	        	z-index: 2;
	        }
	        &:after {
		        content: "";
		        background: transparent url("../img/daily-thumb-gradient.png") repeat-x top center;
		        position: absolute;
		        bottom: 0;
		        left: 0;
		        height: 150px;
		        width: 100%;
		        z-index: 1;
		    }
		    .callout {
		    	color: $white;
		    	font-size: 14px;
		    }
		}
		&.medium {
	        @include sm-and-up {
	            h2 {
	                font-size: 26px;
	                line-height: 30px;
	            }
	            .summary {
	            	display: none;
	            }
	        }
	    }
	}
	.meta {
		p {
			color: $white;
			font-size: 14px;
			font-weight: bold;
		}
	}
}
