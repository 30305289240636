.snippet-blog-feature {
    margin-bottom: 40px;
    position: relative;


    .image-text {
        bottom: 0;
        padding: $section-padding;
        position: absolute;
        width: 85%;
        z-index: 2;

        &,
        a {
            color: $white;
        }
    }

    @include xs {
        
        
    }

    @include sm-and-down {
        h2 {
            margin: 0;
            font-size: 20px;
            line-height: 26px;
        }

        .image-text {
            color: $gray-dark;
            position: relative;
            padding: 15px 0 0;
            a {
                color: $gray-dark;
            }
            .byline {
                color: $gray-dark;
            }
            >* {
                margin-bottom: 0;
            }
        }
    }

    @include md-and-up {
        margin-bottom: 0;
        .image-text {
            h2 {
                font-size: 22px;
                margin: 0 0 5px;
            }

            
        }
        .image {
            background: $black;
            display: block;
            img {
                opacity: 0.75;
            }
        }
    }

    @include md {
        .image-text {
            padding: 10px;
            h2 {
                font-size: 18px;
                line-height: 22px;
            }    
            .by-line {
                font-size: 14px;
            }
        }
        
    }
    .icon {
        display: block;
        background-color: $white;
        padding: 4px;
        position: absolute;
        top: 0;
        right: 0;
        color: $violet;
        z-index: 2;
    }

    &:after {
        content: "";
        background: transparent url("../img/daily-thumb-gradient.png") repeat-x top center;
        position: absolute;
        bottom: 0;
        height: 150px;
        width: 100%;
        z-index: 1;
        @include sm-and-down {
            content: none;
        }
    }

    .blog-headlines {
        .icon {
            min-width: 30px;
        }
    }

    .blog-headline {
        margin-bottom: 10px;

        @include sm-and-up {
            margin-bottom: 20px;
        }

        .icon {
            float: left;
            min-width: 30px;
        }

        i {
            color: $violet;
            position: relative;
            top: 2px;
        }
    }
}
