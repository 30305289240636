
//slick media slider

.slick-slider {
    padding: 0;
}

//common properties
.rotator,
.gallery {
    position: relative;

    //prev/next arrows
    .direction-nav {
        height: 50px;
        padding: 5px 1%;
        position: relative;
        transition: all .2s ease-in-out;

        i {
            font-size: 32px;
        }
        button {
            opacity: 1;
            @include xs {
                color: $gray-dark
            }
            i {
                text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.71);
                @include xs {
                    text-shadow: none;
                }

            }
        }
        .slick-prev {
            // float: left;
            @include xs {
                text-align: center;
                // color: $gray-dark
            }
        }

        .slick-next {
            @include xs {
                text-align: center;
                // color: $gray-dark
            }
        }

        button {
            background-size: 10%;
            background: none;
            border: 0;
            cursor: pointer;
            display: block;
            // height: 100%;
            overflow: hidden;
            padding: 10px;
            position: relative;
            transition: all .3s ease;
            width: 49%;
            z-index: 10;
            line-height: 100%;
            height: auto;
            color: $white;
        }


            background: transparent;
            height: 80px;
            margin: 0;
            padding: 0 5%;
            position: absolute;
            top: calc(50% - 40px);
            width: 100%;

            button {
                background-color: transparent;
                background-size: 80%;
                display: block;
                height: 100%;
                overflow: hidden;
                padding: 5px;
                position: absolute;
                text-align: center;
                width: 10%;
                @include sm-and-up {
                    width: 6%;
                }
                i {
                    font-size: 24px;
                    @include sm-and-up {
                        font-size: 32px;
                    }
                }
            }

            .slick-prev {
                left: 2%;
            }

            .slick-next {
                right: 2%;
                top: 0;
                background-color: transparent;
            }
    }
    .video {
        color: $white;
        font-size: 115px;
        opacity: 0.8;
        position: absolute;
        width: 100%;
        z-index: 90;
        top: 50%;
        left: 0;
        -webkit-transform: translate(0,-50%);
        -ms-transform: translate(0,-50%);
        transform: translate(0,-50%);
        text-align: center;
        font-size: 64px;
        @include xs-wide {
            font-size: 92px;
        }
        @include sm-and-up {
            font-size: 115px;
        }

        &:hover, &:focus {
            opacity: 1;
            text-decoration: none;
        }

    }

}

.media-rotator {
    position: relative;

    .slides {
        margin: 0;
        padding: 0;
        list-style: none;
        z-index: 10; //play button clickable
    }




    //resulting video
    iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 55;
    }
}

.quotes-rotator {
    a {
        color: $gray-medium;
        &:hover {
            text-decoration: none;
        }
    }
    @include sm-and-up {
        ul {
            margin: 0 auto;
        }
    }
}

.gallery {
    img {
        border: 0 !important;
    }
    .direction-nav {
        padding: 0 4%;
        button {
            margin: 0 30px;
        }
    }
}
