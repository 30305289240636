.np-wrapper {

    color: #fff;
    overflow: hidden;
    @include sm-and-down {
        margin-bottom: 30px;
    }

    nav {
        background: $violet;
        padding-left: 25px;
        position: relative;
        overflow: hidden;
        user-select: none;
        i {
            background: #1c1c1c;
            cursor: pointer;
            padding: 8px 0;
            position: absolute;
            width: 25px;
            top: 0;
            text-align: center;
            &.fa-chevron-left {
                left: 0;
            }
            &.fa-chevron-right {
                right: 0;
            }
        }
    }

    .np-sort {
        position: relative;
        text-transform: uppercase;
        ul {
            text-align: center;
            margin: 0;
            background: $gray-dark
        }
        li {
            font-family: $font-headline;
            letter-spacing: 0.1em;
            display: inline-block;
            width: 50%;
            padding: 5px;
            a {
                width: 100%;
                display: block;
                color: $white;
                font-weight: bold;
            }
            &.is-selected {
                background: #313131;
                border-top: 2px solid $violet;
                a {
                    color: $white;
                    font-weight: bold;
                }
            }
            a,
            a:focus,
            a:hover {
                text-decoration: none;
            }
        }
    }

    .np-showdays {
        background: #1c1c1c;
        padding: 0;
        width: 1800px;
        margin: 0;
        transition: margin 0.3s;

        li {
            cursor: pointer;
            display: inline-block;
            padding: 5px 10px;
            text-transform: uppercase;
            font-family: $font-headline;
            font-weight: $font-bold;
            letter-spacing: 0.05em;
        }
        .np-active {
            background: $violet;
        }
    }

    .np-title {
        font-weight: bold;
        font-size: 16px;
        line-height: 20px;
        margin-bottom: 6px;
        a {
            color: #fff;
            text-decoration: none;
            transition: color 0.3s;
            &:hover {
                color: darken( #fff, 20% );
            }
        }
    }

    .np-showtimes {
        li {
            font-size: 16px;
            line-height: 25px;
            margin-right: 10px;

            // sold out shows struck through
            &[data-sold-out="true"] {
                &>a {
                    color: $gray-medium;
                    text-decoration: line-through;

                }
            }
            >a {
                color: $white;
                &:hover {
                    color: $violet;
                }
            }
        }
        a:hover {
            text-decoration: none;
        }
    }

    .np-movie {
        overflow: hidden;
        margin-bottom: 20px;
        img {
            border: 1px solid #fff;
            float: left;
            margin-right: 15px;
            width: 25%;
        }
        .np-movie__info {
            float: right;
            width: calc( 75% - 15px );
        }
    }

    .inline-list {
        margin: 0;
        li {
            display: inline-block;
        }
    }

    .np-today {
        background: #313131;
        padding: 20px;
    }
}

.np-button {
    border: 1px solid #fff;
    color: $violet;
    display: block;
    text-align: center;
    text-transform: uppercase;
    transition: color 0.3s;
    font-weight: $font-bold;
    font-family: $font-headline;
    font-size: 16px;
    line-height: 18px;
    padding: 10px 5%;
    letter-spacing: 0.1em;
    &:hover {
        color: darken( #fff, 10% );
        text-decoration: none;
    }
}

.np-group {
    margin-bottom: 15px;
    h2 {
        border-bottom: 1px solid;
        font-size: 13px;
        font-weight: bold;
        padding-bottom: 3px;
        text-transform: uppercase;
        a {
            color: #fff;
            text-decoration: none;
            transition: color 0.3s;
            &:hover {
                color: darken( #fff, 20% );
            }
        }
        &:first-child {
            margin-top: 0;
        }
    }
}

.np-show-past a {
    color: #8e8e8e;
    cursor: default;
}

.page-now-playing {
    h1 {
        margin-bottom: 15px;
    }
}
.np-interior {

    nav i {
        padding: 16px 0;
    }

    .np-group {
        border-bottom: 1px solid #e5e5e5;
        padding-bottom: 30px;
        margin-bottom: 30px;
        &:last-child {
            border: none;
        }
        h2 {
            border: none;
            font-size: 16px;
            letter-spacing: 0.1em;
            margin: 0;
            a {
                color: $black;
            }
        }
    }

    .np-director, time {
        font-size: 13px;
        font-style: italic;
        color: $gray-text-medium;
        margin: 0 0 5px 0;
    }

    .np-group-linear {
        overflow: hidden;
        // .flexbox & {
        //     display: flex;
        //     flex-wrap: wrap;
        //     justify-content: space-between;
        //     .np-movie {
        //         @include sm-and-up {
        //             width: 48%;
        //         }
        //     }
        // }
        .np-movie {
            @include sm-and-up {
                width: 48%;
                &:nth-child(odd) {
                    float: left;
                }
                &:nth-child(even) {
                    float: right;
                }
            }
        }
    }

    .np-wrapper {
        .np-description {
            color: $gray-text-medium;
            font-size: 14px;
            line-height: 20px;
            margin-bottom: 10px;
            p {
                font-size: 14px;
                line-height: 20px;
            }
        }
        .np-title {
            margin: 0;
        }
        .np-title a {
            color: $violet;
            font-size: 22px;
            line-height: 28px;
            font-weight: normal;
        }
    }

    .np-today {
        background: #fff;
        color: $black;
    }

    .np-showdays {
        width: 1800px;
    }

    .np-showdays li {
        font-size: 18px;
        padding: 10px 20px;
    }

    .np-movie {
        width: 100%;
        img {
            width: 100%;
            @include sm-and-up {
                width: 35%;
            }
        }
        .np-movie__info {
            width: 100%;
            @include sm-and-up {
                width: 55%;
            }
        }
    }
    .section-wrap {
        margin: 10px 0 20px;
    }

}
