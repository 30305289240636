.main-footer {
    margin-top: $section-spacing;

    @include sm-and-up {
        margin-top: $section-spacing-mobile;
    }

    a {
        color: $gray-text-dark;
        line-height: 1;
        &:hover {
            color: $violet;
        }
    }

    .footer-content {
        padding-left: 0;

        @include xs {
            margin-bottom: 30px;
        }

        .footer-logo {
            float: left;
            margin-right: 30px;
            margin-bottom: 30px;
            img {
                width: 100%;
                max-width: 121px;
                @include xs {
                    max-width: 100px;
                }
            }
        }

        .footer-logo,
        .footer-text {

            margin-bottom: $section-spacing-mobile;

            @include sm-and-up {
                // margin-bottom: 0;
            }
            @include xs {
                clear: both;
                width: 100%
            }

        }

        p {
            font-size: 17px;
            line-height: 22px;
            margin-bottom: 5px;
        }

        a {
            color: $violet;
            font-size: 18px;
            font-weight: $font-bold;
            line-height: 23px;

            @include sm-and-up {
                font-size: 20px;
                line-height: 25px;
            }
        }
    }


    .footer-nav-social a {
        display: inline;
        &:hover {
            text-decoration: none;
        }
    }

    nav {
        a {
            display: block;
            margin-bottom: 12px;
        }



        .footer-nav-main,
        .footer-nav-secondary {
            margin-bottom: $section-spacing-mobile;

            @include sm-and-up {
                margin-bottom: 0;
            }
        }


    }
}


@include md {
    //aquire some more space for the links
    .footer-nav-main,
    .footer-nav-secondary {
        padding-left: 0;
        padding-right: 0;
    }
}

.footer-nav-main {
    width: 22%;
    float: left;
    @include xs {
        width: 50%;
    }
    a {
        font-size: 18px;
        line-height: 23px;
        font-weight: $font-bold;

        @include sm-and-up {
            font-size: 16px;
            line-height: 20px;
        }
    }
}

.footer-nav-secondary {
    width: 27%;
    float: left;

    @include xs {
        width: 50%;
        clear: both;
    }

    a {
        font-size: 18px;
        font-weight: $font-semibold;
        line-height: 16px;

        opacity: .7;

        @include sm-and-up {
            font-size: 14px;
            line-height: 20px;
        }
    }
}

.footer-nav-social {
    width: 28%;
    float: left;

    @include xs {
        width: 50%;
    }

    // margin-top: -60px; //use empty space better (align with last item)

    @include sm-and-up {
        margin-top: 0;
    }

    >div {
        margin-bottom: 15px;
    }

    i {
        font-size: 16px;
        margin-right: 10px;

        @include sm-and-up {
            font-size: 14px;
        }

        &:hover {
            color: $violet;
        }

        &.fa-search {
            transform: rotate(90deg);
        }
    }

    >a:last-child { //sign-up link
        font-size: 16px;

        @include sm-and-up {
            font-size: 13px;
        }
    }
}
.social {
    text-align: justify;
    a {
        width: 33%;
        float: left;
        text-align: center;
        font-size: 32px;
    }
}


.global-sponsors {
    background: transparent;
    border-top: 1px solid rgba(0,0,0,0.1);
    text-align: center;
    margin-top: 60px;
    padding: 30px 0;

    @include xs {
        margin-top: 30px;
    }
    img {
        margin: 0 auto;
        width: 96%;
    }
}
