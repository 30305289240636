.page-campaign {

    .content-box {
        &.elevated {
            margin-top: -480px;
        }
    }

    nav {
        margin-bottom: 40px;
    }

    .small-nav {
        border-bottom: 1px solid $gray;

    }

    .image-blurb-tout {
        margin-bottom: 30px;
        @include md-and-up {
            margin-bottom: 45px;
        }

    }

    .campaign-tout {
        .excerpt {
            margin-bottom: 15px;
        }
    }

    .image-touts {
        padding: $section-spacing 0;
        border-bottom: 1px solid $gray;
        margin-bottom: 20px;
        .post {
            @include xs {
                margin-bottom: 20px;
            }
        }
    }


    .main-cta {
        a {
            font-size: 26px;
        }
        padding: $section-spacing 0;
    }
    .excerpt {
        margin-bottom: 10px;
        p {
            font-size: 14px;
            line-height: 20px;
            color: $gray-text-medium;
        }

    }
    .post-content {
        padding: 0 30px;

    }
    .bottom {
        padding-bottom: 30px;
    }
    .set {
        clear: both;
        margin-bottom: 30px;
        @include clearfix;
        &:last-child {
            margin-bottom: 0;
        }
    }
    h2 {
        margin-bottom: 10px;
        a {
            color: $gray-text-dark;
        }
    }
}
