
//
// Individual dots
//
    @mixin dot-inactive-basic($color) {
        display: block;
        background-color: $color;
        border-radius: 100%;
        width: 8px;
        height: 8px;
        border: 2px solid transparent;
    }

    @mixin dot-active-basic($color) {
        background-color: transparent;
        border: 2px solid $color;
        width: 11px;
        height: 11px;
    }


//
// Dots for Slick carousel
//
    @mixin slick-dots-basic($color, $margin) {
        list-style: none;
        padding: 0;
        margin: $margin;
        text-align: center;

        li {
            display: inline-block;
            padding: 7px;
            cursor: pointer;
            vertical-align: middle;

            button {
                display: none;
            }

            &:after {
                content: '';
                @include dot-inactive-basic($color);
            }

            &.slick-active:after {
                @include dot-active-basic($color);
            }
        }
    }
