// WordPress Generated Classes
// http://codex.wordpress.org/CSS#WordPress_Generated_Classes

// Media alignment
.alignnone {
    margin-left: 0;
    margin-right: 0;
    max-width: 100%;
}

.aligncenter {
    display: block;
    margin: ($line-height-computed / 2) auto;
}

.alignleft,
.alignright {
    margin-bottom: ($line-height-computed / 2);
}

// captions on photos

.wp-caption {
    position: relative;

    // wpautop inserted p tags that are empty (but add space) should be hidden
    p:empty {
        display: none;
    }

    .wp-caption-text {
        background: $black;
        color: $white;
        margin: 0;
        padding: 5px 10px;
        font-size: 12px;
        @include sm-and-up {
            position: absolute;
            bottom: 0;
        }
    }
}

@media (min-width: $screen-sm-min) {
  // Only float if not on an extra small device
    .alignleft {
        float: left;
        margin-right: ($line-height-computed / 2);
    }

    .alignright {
        float: right;
        margin-left: ($line-height-computed / 2);
    }
}
