$ndnf-dark: #212122;

// Theme colors for this year
$ndnf-2025-orange: #f58c36;
$ndnf-2025-gold: #f7cc36;
$ndnf-2025-blue: #b0c2fa;
$ndnf-2025-red: #b64522;
$ndnf-2025-red-light: #cd5c1b;

$ndnf-2025-primary: $ndnf-2025-red;
$ndnf-header: $ndnf-dark;
$ndnf-header-hover: $ndnf-2025-blue;

.ndnf-2025-splash {
    .page-container.force-height {
        min-height: 100vh;
    }

    background-image: linear-gradient(
        to right,
        $ndnf-2025-orange,
        $ndnf-2025-gold,
        $ndnf-2025-blue
    );

    @extend .bg-img;
    // @extend .bg-img;
    height: 100%;

    .content {
        p {
            //increase contrast
            color: $black;
            text-shadow: 0px 0px 1px rgba(0, 0, 0, 0.75);

            @include sm-and-up {
                font-size: 22px;
            }

            &.wp-caption-text {
                color: $white;
                font-size: 14px;
            }

            a {
                color: darken($ndnf-2025-blue, 20%); //
                text-decoration: underline;
                &:hover {
                    color: $white;
                }
            }
        }
    }

    .splash-img {
        position: relative;
        margin-bottom: 20px;
        @include sm-and-down {
            padding: 0 30px;
        }
    }
}

//both films + festivals
.single-festivals.new-directors-new-films-2025,
.single-films.section-ndnf2025 {
    $ndnf-link: $ndnf-2025-primary;

    //related posts are hidden
    .recommend-title,
    .related-posts {
        display: none;
    }

    .content-box a {
        color: $ndnf-2025-primary;
    }

    .footer-sponsor {
        margin-bottom: 5px;
        .sponsor-text {
            font-weight: bold;
            position: relative;
            margin-right: 8px;
            font-size: 10px;
            @include sm-and-up {
                font-size: 12px;
            }
        }

        .sponsor-logo {
            margin-bottom: 5px;
            max-width: 135px;
            @include sm-and-up {
                max-width: 180px;
            }
        }
    }

    .footer-ndnf2025 {
        margin: 0;
        background-color: $ndnf-dark;
        color: $white;
    }

    .header-ndnf2025,
    .page-container {
        &::after {
            content: '';
            display: block;
            width: 100%;
            height: 9px;
            border: 0;
            outline: none;
            background-image: linear-gradient(
                to right,
                $ndnf-2025-orange,
                $ndnf-2025-gold,
                $ndnf-2025-blue
            );
        }
    }
}

//styles for the New Directors New Films Festival
.single-festivals.new-directors-new-films-2025 {
    //the date/title are in the header so hide from the body
    .single-intro {
        display: none;
    }

    //play button
    a.video-lightbox {
        width: auto;
        left: calc(50% - 36px);
        @include sm-and-up {
            left: calc(50% - 20px);
        }
        font-size: initial;

        background: $black;
        opacity: 0.5;
        padding: 16px 24px;
        border-radius: 50%;
        i {
            font-size: 36px;
            position: relative;
            left: 3px;
        }
    }
}

.single-films.section-ndnf2025 {
    .promo-callout {
        color: $ndnf-2025-primary;
    }

    .event-date {
        @include sm-and-up {
            background-image: none;
            time {
                top: 0;
            }
        }
    }
}

.header-ndnf2025 {
    background-color: $ndnf-header;
    position: relative;
    border: 0;

    &,
    a {
        color: $white;
    }

    a {
        &:hover {
            color: $ndnf-header-hover;
        }
    }

    .header-social-nav {
        z-index: 1;
        position: relative;
        @include xs {
            margin-top: 15px;
        }
        @include sm {
            float: left;
            top: 10px;
            margin-top: 10px;
            margin-bottom: -30px;
        }
        @include md-and-up {
            float: right;
        }

        .social-links {
            position: static;
            margin: auto;
            display: table;
            a {
                color: $white;
                font-weight: 700;
                letter-spacing: 0.1rem;
                &:hover {
                    &,
                    i {
                        color: $ndnf-header-hover;
                    }
                }
            }
        }

        //hide some of the items
        .signup-link,
        .cart,
        .login,
        .app-link,
        .yt-link {
            display: none;
        }

        //align social links group with left and right edges
        .fb-link {
            padding-left: 0;
        }

        .tw-link {
            position: relative;
            top: 1px;
        }

        .yt-link {
            padding-right: 0;
        }

        i {
            color: $white;
        }
    }

    .navbar {
        height: auto; //no height imposed
        @include xs {
            padding: 0;
        }
    }

    .navbar-logo-wrap {
        padding: 25px 0;
        width: 100%;

        @include sm {
            max-width: 480px;
            margin: 0 auto;
            padding: 20px 0 0;
            text-align: center;
        }
        @include md-and-up {
            width: 48%;
            float: left;
        }
        @include lg {
            width: 38%;
        }
    }
    .navbar-nav-wrap {
        @include sm {
            width: 100%;
        }

        @include md-and-up {
            width: 52%;
            float: right;
        }
        @include lg {
            width: 62%;
        }
    }

    .navbar-brand {
        img {
            position: relative;
            max-width: 180px;
            bottom: 10px;
            @include sm {
                bottom: 0;
                max-width: 330px;
            }
            @include md {
                bottom: -10px;
                max-width: 360px;
            }
            @include lg {
                bottom: -10px;
                max-width: 460px;
            }
        }
    }

    .navbar-logos {
        @include xs {
            width: 80%;
            float: left;
        }
        @include xs-wide {
            width: 75%;
            float: left;
        }
        @include sm-and-up {
            margin-top: 33px;
            display: flex; //vertically center
            align-items: center;
        }
        @include lg {
            margin-top: 36px;
        }

        a {
            display: inline-block;
            &:not(:last-child) {
                margin-right: 15px;
                @include sm-and-up {
                    margin-right: 45px;
                }
            }
            img {
                width: 100%;
            }
        }
        .ndnf-logo {
            // width: 62%;

            @include xs {
                margin-bottom: 20px;
                display: block;
            }
            @include xs-wide-and-up {
                width: 212px;
            }
        }
        .filmlinc-logo {
            // width: 12%;
            position: relative;
            width: 115px;
            @include md-and-up {
                width: 140px;
            }
            // vertical-align: bottom;
            // top: -6px;
        }
        .moma-logo {
            max-width: 74px;
            vertical-align: bottom;
            margin-bottom: 10px;

            @include sm-and-up {
                max-width: 82px;
                margin-bottom: 0;
            }
            @include lg {
                max-width: 90px;
            }
        }
    }

    .navbar-header {
        width: 100%;
        // overwrite inherited width from _navbar.scss
    }

    .navbar-toggle,
    .icon-bar {
        border-color: $white;
    }

    .navbar-toggle {
        margin-right: 0;
    }

    .icon-bar {
        background-color: $white;
    }

    .date-row {
        @include lg {
            margin-bottom: 10px;
        }
    }

    time,
    .bar {
        min-height: 20px;
        @include md-and-up {
            min-height: 40px;
        }
    }

    .bar {
        // border-bottom: 3px solid $white;
    }

    .event-date {
        text-align: center;
        @include sm-and-up {
            background: url('../img/ndnf-2025-header-rule.png') repeat-x bottom center;
            text-align: right;
        }
    }

    time {
        font-weight: 700;
        font-size: 26px;
        text-align: center;
        position: relative;
        @include sm-and-up {
            background: $ndnf-header;
            text-align: right;
            padding-left: 23px;
        }
        width: 100%;

        @include sm {
            // font-size: 13px;
            position: relative;
            top: 9px;
        }
        @include md-and-up {
            // font-size: 16px;
            // top: 20px;
            font-size: 26px;
            // baseline position to bottom of line.
            position: relative;
            top: 11px;
        }
        @include lg {
            font-size: 26px;
        }
    }

    //differences from default series nav look
    .series-nav {
        border: 0;
        margin: 0;
        margin-top: 2px;

        display: flex;
        flex-direction: column;
        a {
            flex-grow: 1;
            text-align: center;
            margin: 0;
        }

        @include sm-and-up {
            flex-direction: row;
            justify-content: space-between;
        }

        @include md-and-up {
            width: 100%;
            position: relative;
            right: -32px;
        }

        > a:hover {
            color: $ndnf-header-hover !important;
            text-decoration: none;
        }

        a[aria-controls] {
            text-transform: uppercase;
            font-family: $font-headline;
            font-size: 28px;
            font-weight: 700;
            border-bottom: 0;
            padding: 12px 0;
            @include sm {
                font-size: 22px;
                padding: 0;
            }
            @include md {
                font-size: 18px;
                padding-top: 24px;
                line-height: 100%;
            }
            @include lg {
                letter-spacing: 0.15em;
                font-size: 24px;
            }
        }
    }

    .navbar-collapse {
        overflow: hidden;
    }
} //.header-newdirectors2025
