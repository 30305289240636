
//tertiary nav is the left side nav that lets you go to other pages .

.tertiary-nav-col {
    +div {
        @include sm-and-up {
            border-left: 1px solid rgba(0, 0, 0, .1);
        }
    }
}


.nav-tertiary {
    font-weight: 700;
    line-height: 33px;

    .header-ling {
        color: $gray-text-medium;
    }

    a {

        color: $gray-text-dark;
        font-size: 14px;
        line-height: 18px;
        margin-bottom: 12px;
    }

}
