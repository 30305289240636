.page-template-template-email-subscribe {
    .main-header {
        margin: 0;
    }
    .header {
        padding: 50px 0;
        @include sm-and-up {
            min-height: 360px;
            background-size: 100% 100%;
        }

        .title {
            color: $white;
            font-weight: bold;
            letter-spacing: 1px;
            line-height: 1.3;
            font-size: 20px;
            @include sm-and-up {
                font-size: 36px;

            }
        }
        .header-text {
            color: $white;
        }

        .blurb p {
            color: $white;
            font-weight: 600;
            font-style: italic;
        }
    }



    .email-sub-form {
        margin: 15px 0;

        .form-group {
            margin-bottom: 20px;
        }

        [type="email"], [type="text"]  {
            color:  $gray-text-dark;
        }

        label {
            font-weight: bold;
        }

        .description {
            margin-bottom: 20px;
            font-style: italic;
        }

    }

    .checkbox {
        margin-bottom: 5px;

        //checkbox effect. inspired by: http://bootsnipp.com/snippets/featured/animated-radios-amp-checkboxes-nojs
        [type="checkbox"] {
            display: none; //visually, the css checkbox is what displays

            //css checkbox check effect
            & + .cr > .cr-icon {
                transform: scale(3) rotateZ(-20deg);
                opacity: 0;
                transition: all .3s ease-in;
            }

            &:checked + .cr > .cr-icon {
                transform: scale(1) rotateZ(0deg);
                opacity: 1;
            }

            &:disabled + .cr {
                opacity: .5;
            }
        }

        label {
            &::after { //visual css checkbox
                content: '';
                display: table;
                clear: both;
            }
        }

        .cr {
            position: relative;
            display: inline-block;
            border: 1px solid #a9a9a9;
            border-radius: .25em;
            width: 1.3em;
            height: 1.3em;
            float: left;
            margin-right: .5em;
        }

        .cr .cr-icon {
           position: absolute;
           font-size: .8em;
           line-height: 0;
           top: 50%;
           left: 20%;
       }

    }

    .description {
        margin-left: 20px;
    }

}
