//.mfp-container {
//      max-height: 100vh;
// }

.mfp-content {
    @include xs {
        vertical-align: top;
    }
}

.lightbox-content {
    .mfp-close {
        color: #eee;
        &:hover {
            color: #fff;
        }
    }

    .ad-lightbox {
        @include xs {
            // give a width
            max-width: 300px;
        }

        > div {
            // vertically center
            @include xs {
                display: flex;
                align-items: center;
            }
        }
    }
}
