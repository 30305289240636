.section-instagram-slider {
    background-color: $white;
    margin-bottom: 45px;
    padding: 15px 0 0;

    .full-width-posts {
        padding-top: 0;
    }

    .title {
        padding: 0 15px 30px;

        a:not(.icon) {
            color: inherit;
            display: inline-block;
            text-align: center;
        }
        a.icon {
            &:hover {
                text-decoration: none;
            }
        }
    }
}

.insta-rotator {
    position: relative;

    .slide {
        height: 160px;
        margin-right: 10px;
        @include md-and-up {
            height: 210px;
        }

        //img bg
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
         img {
            min-height:100%;
            min-width: 100%;
            object-fit: cover;
        }
    }

    .slick-arrow {
        top: 50%;
        transform: translateY(-50%);
        height: 36px;
    }



    .slide {
        position: relative;
        max-width: 740px;
    }

    .slick-arrow {
        color: $violet;
        opacity: 0.8;
        background-color: transparent;
        border: 0;
        padding: 0;
        position: absolute;
        font-size: 24px;
        &:hover {
            opacity: 1;
        }
        @include md-and-up {
            font-size: 36px;
        }
    }

    .slick-next {
        right: 45px;
        @include sm {
            right: 90px;
        }
        @include md-and-up {
            right: 120px;
        }
    }

    .slick-prev {
        left: 45px;
        @include sm {
            left: 90px;
        }
        @include md-and-up {
            left: 120px;
        }
    }

    .reveal-gradient-overlay {
        display: none;
    }

    .video-play {
        position: absolute;
        width: 100%;
        z-index: 90;
        top: 50%;
        transform: translate(0, -50%);
        text-align: center;
        font-size: 100px;
        color: $white;
        opacity: .7;
        font-size: 45px;
        &:hover {
            opacity: 1;
        }
    }

}
