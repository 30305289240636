.films-series {
	.content {
		padding-top: 20px;
	}
	.intro {
		margin: 0 0 30px;
		@include clearfix;
	}
	h1 { 		
		margin-right: 30px;
		margin-bottom: 0;
		@include lg {
			float: left;	
		}
	}
	.filter-by-post-type {
		display: inline-block;
		text-align: left;
		li {
			display: inline;
			padding-right: 5px;
			font-weight: bold;

		}
		@include md-and-up {
			margin-top: 20px;
		}
		a {
			padding: 3px 5px;
			border-radius: 4px;
			&.active, &:focus {
				background: rgba(0,0,0,0.1);
				text-decoration: none;
				color: $gray-dark;
			}
		}
	}
	.view-schedule {
		display: block;
		margin-top: 0;
		clear: both;
		a {
			margin-right: 20px;
		}
	}
	.list {
		.related-post, .feature-with-overlay {
			margin-bottom: 0;
		}
		@include sm-and-up {
			.row {
				// margin-bottom: 40px;
			}	
		}
		.header {
			margin: 30px 0 15px;
		}
		
		.medium {
			max-height: none;
		}
	}
	@include xs {
		.row {
			margin-bottom: 0;
		}
		.item {
			margin-bottom: 30px;
		}
	}
	.title-wrap {
		width: 100%;
		@include md-and-up {
			width: auto;
			float: left;
			// margin-bottom: 30px;
		}
		a {
			margin-right: 10px;
		}
		.links {
			@include lg {
				position: relative;
				top: 19px;	
			}
		}
	}
	.sidebar {
		position: relative;
		@include md-and-up {
			top: 94px;
		}
		@include sm {
			// top: 114px;
		}
	}
	.item {
		margin-bottom: 30px;
	}
	.isotope-item {
	  z-index: 2;
	}

	.isotope-hidden.isotope-item {
	  pointer-events: none;
	  z-index: 1;
	}
}