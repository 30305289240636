//  a box with an image, short title, blurb and link
// example use: support us

.image-blurb-tout {

    h2 {
        padding: 10px 0 0;
        color: $gray-text-dark;
        font-size: 22px;
        line-height: 25px;
    }

    p {
	    margin-bottom: 10px;
    }
    .post {
        margin-bottom: 30px;
    }
    
}
