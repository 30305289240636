//Upgraded styles for Winter 2023 upgrade of tessitura

// tessitura specific css variables
:root {
    --tn-brand-primary: #{$brand-primary};
}


.page-tessitura-embed-2023 {


    color: $gray-text-dark;
    font-family: $font-sans;
    font-weight: $font-regular;
    background-color: $white; //the center bg should be white (we change header/footer/etc

    .alert-bar {
        margin-bottom: 0;

    }


    .main-header {
        border: 0;
        .header-ads {
            background-color: $white;
        }
    }

    .header-social-nav {
        .social-links {
            @include md-and-down {
                position: static;
            }
        }

        .login {
            @include sm {
                position: absolute;
                right: 0;
                font-size: 22px;
            }
            @include md {
                position: absolute;
                right: 0;
                font-size: 22px;
            }
        }
    }

    .global-sponsors {
        border: 0;
        background-color: $white-off;
        margin-bottom: 0;

    }

    // match grid
    .container {
        @include lg {
            width: 1400px;
        }
    }

    #root+.container {
        @include xs {
            padding-left: 30px;
            padding-right: 30px;
        }
    }




        // primary theme color overrides
        .btn-primary,
        .btn-primary[type="submit"],
        .btn-info,
        .tn-btn-datepicker .btn-primary
         {
            background-color: $brand-primary;
            &:hover, &:active, &:focus {
                background-color: darken($brand-primary, 10%);
            }
        }


    // Utility nav (promo code, login button, view cart
    .tn-utility-nav {

        .tn-utility-nav-modal-promo-code-form__btn-open-modal {
            margin-right: 30px;

            @include sm-and-up {
                display: none; // hide the promo code button. the "Submit" is used instead

            }
        }

        .tn-utility-nav-inline-promo-code-form__input-container {
            display: flex;
            gap: 10px;
        }

        .tn-utility-nav-cart-link {
            @include sm-and-up {
                margin-right: 30px; // space for the quantity-badge

            }
        }


        // the counter
        .tn-utility-nav-cart-link__quantity-badge {
            border: 1px solid $brand-primary; // since it's not in the button anymore, we need to add a border
            right: 42px;
            @include sm-and-up {
                right: -25px;
                top: 5px;
                padding-left: 1px;

            }
        }
    }


    .modal-header .close {
        .tn-icon--x {     // modals already use pseudo elements, so we need to override the icon
            display: none;
        }
    }

    // calendar overrides

    .tn-btn-datepicker__btn-container {
        .tn-icon--calendar {
            position: relative;
            top: 4px;
        }
    }
    // common to any event item (day, week, month)
    .tn-events-calendar__event {
        padding: 10px 5px;

        @include xs {
            min-width: 300px;
        }
        @include sm-and-up {
            text-align: start;
            letter-spacing: 0.5px;
            width: 100%;
        }

    }

    .tn-events-calendar__day-event-list {
        display: flex;
        flex-direction: column;
        gap: 15px;


    }

    // month specific overrides
    .tn-events-calendar-view-month {

        .tn-events-calendar__day-event-list-item {
            align-items: center;
        }
    }

    .tn-events-calendar__event-time {
        position: static;
    }

    // day specific overrides
    .tn-events-calendar-view--day {
        .tn-events-calendar__event {
            min-width: 300px;
        }


    }

    // week specific overrides
    .tn-events-calendar-view--week {
        .tn-events-calendar__event {
            min-width: auto;
        }
    }


    .tn-events-calendar__day-event-list-item {
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: 80px;

        @include xs {
            margin: 15px auto;
        }
    }

    .tn-events-calendar__table {
        .tn-events-calendar__event-name {
            white-space: initial; // prevent the text from expanding the container
        }
        .tn-events-calendar__event-name, .tn-events-calendar__event-time  {
            font-weight: $font-semibold;
        }
    }

    .tn-btn-datepicker {
        .btn-primary[disabled] {
            opacity: 1;
        }
    }

    .tn-event-detail {
        a {
            color: $brand-primary;
            &:hover, &:active, &:focus {
                color: darken($brand-primary, 10%);
            }
        }

        .tn-syos-screen-button {
            background-color: $brand-primary;
            &:hover, &:active, &:focus {
                background-color: darken($brand-primary, 10%);
            }
        }
    }

    .tn-syos-seat-map__stage-indicator-label {
        color: $black;
    }

    .main-footer {
        background-color: $white-off;
        margin-top: 0;

        .footer-nav-main  {
            a {
                color: inherit;
            }
        }
    }


    .primary-mobile-nav {

        // the close button. css draw an X (fa-close was not working)
        .nav-closer {
            &::before, &::after {
                content: "";
                width: 2px;
                height: 16px;
                background-color: #000;
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%,-50%) rotate(-45deg);
                transform-origin: center;
            }

            &::after {
                transform: translate(-50%,-50%) rotate(45deg);
            }
        }
    }

    // tickets selector
    form.tn-ticket-selector {
        .tn-ticket-selector__fixed-amount-input {
            border-color: transparent;
            font-weight: $font-bold;
        }
    }


    // single showtime/event detail page (e.g.: /production_season_id/performance_id,  /69970/69974 )
    .tn-events-detail-page {

        // hide the ticket selection section heading and the promo heading with repetitive info
        .tn-event-detail__ticket-selection-section-heading, .tn-prod-season-header__date-container {
            display: none;
        }
    }

    // cart page (showing selected events added to cart)
    .tn-cart-details-page {

        .tn-cart__currency-format-message {
            display: none;
        }

    }
}

