
.page-template-template-ndnf-splash {

    .page-container {
        padding: 40px 0;
    }

    .container.header {
        @include xs {
            padding: 0;
        }
    }


}
