.admission {

    .title {
        font-weight: bold;
    }
    .prices {
        margin-bottom: 10px;
    }
    .price {
        font-weight: $font-bold;
        float: left;
        min-width: 40px;
    }

    .pass-info {
        margin-bottom: 10px;
    }

    .offer {
        margin-bottom: 20px;
        em {
            margin-bottom: 10px;
            display: block;
        }
        .cta {
            display: block;
        }
    }

    .share-links {
        margin-top: 30px;
    }

    .sponsors {
        img {
            max-width: 200px;
        }
    }
}
