@mixin sold_out_link {
    text-decoration: line-through;

    &, span {
        color: $gray-medium;
    }
}

// common ticket link sold out styles (on single and series, etc pages)
.showtime[data-sold-out-standby=true]  .where-when, // film showtimes
.films-group .showtime[data-sold-out-standby=true]  .ticket-link, // guide showtimes
ul.co-showtimes-list a[data-sold-out-standby=true]{ //calendar showtimes


    @include sold_out_link;

    span{ // gray out past links
        &:hover {
            text-decoration: none;
        }
    }

}
a.ticket-link, ul.co-showtimes-list a{

    &:not([href]){ // gray out past links
        color: $gray-medium;
        &:hover {
            text-decoration: none;
        }
    }


}

.series-nav-wrap {
    margin-left: -30px;
    margin-right: -30px;
}
.series-nav {
    border-top: 1px solid $gray;

    li {
        display: block;
        float: left;

    }

    a {
        color: $gray-text-dark;
        margin-right: 20px;
    }
    // Bootstrap overrides
    .nav-tabs {
        .active {
            a {
                background: #f5f5f5;
                color: #7f7f7f;
                border-bottom-color: transparent;
                cursor: default;
                border: 0;
                &:hover, &:focus {
                    border: 0;
                    color: #7f7f7f;
                    background: #f5f5f5;
                }
            }
        }
        a {
            border: 0;
            border-radius: 0;
            &:hover, &:focus {
                background: #f5f5f5;
                border: 0;
                color: #7f7f7f;
            }
        }
    }
}

.single-series, .single-festivals {
    #about {
        img {
            width: 100%;
        }
    }
    .admission {
        .brief-tickets {
            margin-top: 15px;
            padding: 0 15px 0 0;
            p {
                font-size: 14px;
                line-height: 20px;
                margin-bottom: 0;
            }
        }
    }

    .showtime[data-sold-out-standby=true] a {
        @include sold_out_link;
    }
}
.series-under-content {
    margin-top: 30px;
    h3 {
        font-weight: $font-bold;
    }

    h3, a, p {
        font-size: 14px;
    }
}

.series-schedule {
    .entry {
        margin: 30px 0;
    }
}

.series-films {
    .film {
        margin-bottom: 30px;
        p {
            font-size: 14px;
            line-height: 18px;
        }
        img {
            margin-bottom: 30px;
        }
    }
}

.series-schedule {
    .event-entry-info {
        time, p {
            font-size: 14px;
            line-height: 18px;
            margin-bottom: 5px;
            display: block;
        }
        time {
            display: flex;
            align-items: center;
        }
        .past {
            color: $gray-text-medium;
        }
        .fa {
            color: $gray-text-medium;
            padding-right: 5px;
            font-size: 14px;
        }

        .showtime {
            &:not([href]){ // gray out past links
                color: $gray-medium;
                &:hover {
                    text-decoration: none;
                }
            }
        }
    }
    .date-headline {
        text-transform: uppercase;
        border-bottom: 1px solid $gray;
        padding-bottom: 15px;
        font-size: 16px;
        font-weight: bold;
        margin: 30px 0 15px;
    }

}

.post-about {
    .post-content {
        // padding: 0 30px 30px;

    }
}

.quotes-rotator {
    blockquote {
        margin: 0;
        padding: 30px;
        border: 0;
        p {
            font-size: 26px;
            line-height: 32px;
            &:before {
                content: "\201C";
            }
            &:after {
                content: "\201D";
            }
        }
        .byline {
            font-size: 14px;
            line-height: 16px;
            letter-spacing: 0.15em;
            margin-top: 15px;
        }
    }
    .direction-nav {
        display: none;
    }
}

.series-archive {
    .post {
        margin-bottom: 30px;
        &:last-child {
            margin-bottom: 0;
        }
    }
    p {
        font-size: 14px;
        line-height: 18px;
    }
}

.single-intro {
    @include xs {
        margin-bottom: 0;
        padding-bottom: 0;
    }
}

.films-group {

    .header {
        h2, .sponsor-text, .sponsor-image {
            display: inline-block;
        }
        h2 {
            margin-right: 10px;
        }
        .sponsor-text {
            margin-right: 10px;
        }
    }

    .three-columns {
        margin-top: 10px;
        strong.callout {
            display: block;
            margin-bottom: 5px;
        }
        .item.list>.row {
            @include sm-and-up {
                margin-bottom: 10px;
            }
        }
        .day-showtimes {
            margin-bottom: 10px;
        }
        time {
            display: block;
        }
        .showtime {
            display: inline-block;
        }
    }
    h3 {
        margin-bottom: 20px;
        a {
            color: $gray-text-dark;
        }
    }
    .blurb {
            margin-bottom: 20px;
    }

}
