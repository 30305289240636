//
// Global to all archive pages
//

//all archive pages
body.blog,
body.archive {
    h2.archive-title-wide {
        color: #454545;
        font-size: 22px;
        line-height: 1.1;
        margin: 0 0 5px;
        a {
            color: inherit;
        }

        @include sm {
            font-size: 16px;
        }
    }

    .by-line {
        &, a {
            color: #787878;
        }
    }

    #archive-load-more {
        font-size: 20px;
        font-weight: 700;


    }

    .overlay.darken {
        z-index: 0; //over the image but
    }
}

.archive-content {
    padding: 0 30px 30px;
    @include md {
        padding: 0 60px 0;
    }

    .archive-item {
        margin-bottom: 15px;

        >div {
            margin-bottom: 20px;
        }

        .meta {
            font-weight: 600;
            letter-spacing: 0.1em;
        }

        .meta-title {
            margin-left: -6px;
        }


        .item-text>div {
            margin-bottom: 10px;
        }

        .item-text {
            padding: 0 15px;
        }
    }
}

// daily page (/daily, /daily/2016/04/ , /daily/category/film-society/ , /daily/post-type/photo-gallery/ ... )
.blog, .archive {

    .daily-logo {
        max-width: 210px;
        margin: 30px 0 0;
        display: inline;
    }

    .daily-term-id-167 { //podcast
        .play-btn {
            &:hover {
                text-decoration: none;
            }
            i {
                color: $gray;
                font-size: 60px;
            }
        }
    }

    .daily-term-id-153 {
        a+.overlay {
            left: 0;
        }
        .video-lightbox {
            i {
                color: $white;
                font-size: 60px;
            }
        }
    }

} //.blog, .archive

.daily-post-type-links {
    //align with logo
    position: relative;
    bottom: 7px;

    //flush right
    float: right;
    // at 1018 it this element breaks. Bump it down onto a new line.
    @include breakpoint-max(1018px) {
        clear: both;
        width: 100%;
        margin-top: 10px;
    }
    @include sm-and-down {
     // float: left;
     // margin-top: 10px;
    }
    li:first-child {
        a {
            margin-right: 0
        }
    }
    li:last-child {
        a {
            margin-left: 0
        }
    }

    .nav-tabs {
        @include breakpoint-min(1018px) {
            float: right;
        }

    }

    .nav-tabs > li > a {
        padding: 5px 12px;
        margin: 5px 10px;
        border-radius: 0;
        @include xs {
            padding: 10px 5px;
            font-size: 13px;
        }
        &:first-child {
            margin-right: 0
        }
        &:last-child {
            margin-left: 0
        }
    }

    a {
        color: $black;
        font-weight: $font-bold;
        text-decoration: none;
        padding: 5px;
        &.active {
            background-color: #eeeeee;
            font-weight: 400;
        }
        @include sm-and-up {
            font-size: 16px;
        }

    }
}

.archive-header {
    background-color: $black;
    color: $white;
    // min-height: 250px;
    // float: right;
    .archive-header-image {
        display: none;
        @include xs {
            display: block;
        }
    }
    div[data-mh='archive-header'] {
        // float: right;
    }

    .filter-wrap {
        float: right;
        margin-bottom: $section-spacing-mobile;
        > * {
            float: left;
        }
        select {
            width: auto;
            border: 0;
            font-size: 14px;
            padding-top: 0px;
            appearance: none; //hide the native down arrow

            //put the select it in front of the arrow on the z-index so clicking the arrow  opens the select as well
            position: relative;
            z-index: 1;
            background-color: transparent;
            max-width: 115px;
            box-shadow: none;
            text-align: right;

            &:focus { //the select has an arrow, so doesn't need a shadow to stand out
                box-shadow: none;
            }

            option {
                &:first-child {
                    display: none; //no need to show the label twice
                }
                background-color: black;
                font-family: $font-sans;
            }

        }

        >span {
            padding: 8px 12px 6px 0px;
        }

        @include sm {
            >span {
                font-size: 16px;
            }
            select {
                font-size: 14px;
                min-width: initial;
            }
        }

    } // /.filter-wrap

    //the <selects> come with a down chevron. implement as pseudo class
    .select-wrapper {
        position: relative;
        &:after {
            content: $fa-chevron-down;
            font-family: 'icomoon';
            position: absolute;
            top: 5px;
            right: 20px;


        }
        &.by-date {
            @include sm {
                clear: both;
            }
            &:after {

            }
        }
        &.by-category:after {
            right: 20px;
        }

    }

} // /.archive-header

.archive-header-intro {

    background-repeat: no-repeat;

    @include lg {
        background-size: 62%;
        background-position: left bottom;
    }
    @include md {
        background-size: 75%;
        background-position: -120px 0;
    }
    @include sm {
        background-size: 100%;
        background-position: -200px 0;
    }
    @include xs {
        background-image: none;
        background-size: 0;
    }

    &, h1, .blurb p  {
        color: $white;
    }

    h1 {
        clear: both;
        font-size: 30px;
        i {
            font-size: 24px;
            margin-right: 0;
        }

    }

    .blurb {
        @include sm-and-up {
            margin: 0 50px 0 40px;
        }

        p {
            font-size: 16px;
            color: $gray;

        }
    }

    .social-links {
        border-top: 1px solid rgba(255,255,255,0.2);
        padding-top: 15px;

        .meta, .btn {
            float: left;
            margin-right: 8px;
        }

        .btn {
            padding: 5px;
            margin-bottom: 8px;
            border-radius: 0;
            letter-spacing: 0;
            &:focus {
                background: $violet;
            }
        }

        .meta {

            line-height: 2.8; //horizontally  align with buttons
            letter-spacing: 0.1em;
            font-family: $font-sans;
            font-weight: 600;
            color: lightgrey;
            font-size: 13px;
        }
    }

    .text-wrapper {
        padding: $section-spacing-mobile 0;

    }

    .text-content {
        padding: 50px 15px;

        @include sm {
            h1, i {
                font-size: 18px;
            }

            .blurb {
                margin-left: 0;
                p {
                    font-size: 13px;
                }
            }
        }
    }

    .subtitle {
        color: $gray-medium;
        font-family: $font-sans;
        margin-bottom: 10px;
        letter-spacing: 0.1em;
        font-size: 13px;
        line-height: 16px;
        font-weight: 600;
    }

    &.type-daily-default {

        .main-content {
            background-repeat: no-repeat;
            background-position: left bottom;
            @include lg {
                // background-size: 62%;
                // background-position: left bottom;
            }
            @include md {
                // background-size: 75%;
                // background-position: -120px 0;
            }
            @include sm {
                background-size: 85%;
                // background-position: -200px 0;
            }
            @include xs {
                background-image: none;
                background-size: 0;

            }
        }

        .overlay {
            @include xs {
                position: relative;
            }
        }

        .filter-wrap {
            float: left;
            @include xs {
                float: none;
                width: 100%;
            }

        }


        .daily-post-type-links {
            float: none;
            position: static;
            li {
                float: none;
                margin-bottom: 12px;
            }
            a {
                color: white;
            }
            @include xs {
                width: 100%;

            }
        }

        .subtitle {
            clear: both;
        }

        select>option {
            background-color: $gray-text-dark;
        }

        .text-content {
            >img {
                max-width: 200px;
            }
            @include sm {
                width: 70%;
                float: right;
            }
            @include md-and-up {
                width: 60%;
                float: right;
            }

            img {
                margin-bottom: 15px;
            }
            .blurb {
                margin: 0;
                p {
                    font-size: 14px;
                }
            }
            @include xs {
                padding: 30px 15px;
            }
        }
        .links {
            padding: 15px 55px;
            background-color: $gray-text-dark;
        }

    } //

}

//all archive pages
body.archive {

    &.term-236, &.term-167, &.term-153 { //term article, podcast, video
        margin-bottom: 30px;

        .by-line {
            margin-bottom: 5px;
            font-size: 13px;
        }
        .blurb {
            @include sm {
                font-size: 13px;
            }
        }
    }

}

//Daily Post Type pages (article, photo gallery, ...)
//When styling terms, we'll use term ids rather than slugs as they sometimes get renamed
.tax-daily_post_type {

    .content-box {
        padding: 30px 0;
        clear: both;
    }

    &.term-238 { //term photo-gallery

        .mfp-title {
            font-size: 16px;
            font-weight: 600px
        }


        //adjust bootstrap grid spacing. items should have 10px of space between them
        div.term-238 { //photo gallery item
            @include sm-and-up {
                padding: 5px;

                .teaser-gallery {
                    margin-bottom: 0;
                }
            }
        }

        //more space for gallery index to use.
        .archive-content {
            padding-left: 30px;
            padding-right: 30px;
        }
    }


    &.term-153 { //term video

        .term-153 { //each video item
            padding: 15px;
        }

        .video-item {
            margin-bottom: 0;
        }

        .fa-play-video {
            font-size: 40px;
            @media (min-width: $screen-xs) {
                font-size: 80px;
            }
            @media (min-width: $screen-lg) {
                font-size: 90px;
            }
        }

        .video-lightbox {
            z-index: 1; //video play link has higher click precendence than background overlay link.
            color: $white;
            opacity: 0.8;
            &:hover {
                opacity: 1;
            }
        }

        .item-text {
            padding: 0;
            margin: 15px 0;
        }


        &.featured {
            //the featured video item has text overlapping images
            .item-text {
                position: relative;
                opacity: 1;
                margin-top: -80px;
                padding: 15px;
            }
            h2 {
                color: $white;
                text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.7);
                font-size: 16px;
            }
        }

    } // .term-video


    //DEBUG
    // .ad.center-block {
    //     display: none;
    // }
} //.tax-daily_post_type


//the other archives (/daily, /daily/2016/03, /daily/category/news )
.blog.daily, .archive {
    .content-box {
        padding: 30px 0;
    }
}
