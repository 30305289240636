.page-template-template-calendar {


    &.has-calendar-header {
        .container.hidden-xs .calendar {
            @include md-and-up {
                margin-top: -70px;
            }
        }
    }

    .calendar-header {
        margin-bottom: 30px;
        @include md-and-up {
            margin-bottom: 0;
        }

        .header-text {
            padding: 30px 20px;
        }

        .title {
            font-weight: 700;
            margin: 0 0 5px;
            font-size: 18px;
            @include sm-and-up {
                font-size: 22px;
            }
        }

        .header-content {
            background-color: $white;
            box-shadow: 0 2px 4px 0 rgba(0,0,0,0.50);
            border-radius: 8px;
            position: relative;
            z-index: 1;
            @include md-and-up {
                float: right;
                max-width: 480px;
            }
        }

        .header-text {

            @include xs-wide {
                max-width: 50%;
            }
            @include xs-wide-and-up {
                float: left;
            }
            @include md-and-up {
                max-width: 300px;
            }

        }
        .col-img {
            @include xs {
                text-align: center;

            }
            @include xs-wide-and-up {
                float: right;
                overflow-y: hidden;
                padding-right: 200px;
            }

            img {
                max-width: 120px;
                @include xs-wide-and-up {
                    position: absolute;
                    right: 20px;
                    top: -14px;
                    border: 5px solid #fff;
                    border-bottom: 0;
                }

            }
        }

        .calendar-header-links {
            a {
                font-weight: 700;
                font-size: 14px;
                @include sm-and-up {
                    font-size: 16px;
                }
                &:not(:last-child){
                    margin-right: 10px;
                }
            }
        }

    }

    .header-followup {
        margin-top: 10px;
        padding: 0 20px;
        font-size: 16px;
        position: relative;
        z-index: 1;

        @include md-and-up {
            float: right;
            max-width: 480px;
        }

        .followup-blurb {
            margin-right: 10px;
            font-weight: 700;
        }
        nav {
            display: inline-block;
            a {
                font-weight: 700;
                font-size: 14px;
                @include md-and-up {
                    font-size: 16px;
                }
                &:not(:last-child){
                    margin-right: 8px;
                }
            }
        }
    }

}
