
.home-feature { //the heros of the home page


    // margin-bottom: $section-padding;

    .arrow {
        font-size: 16px;
    }

    .lead {
        color: $gray-text-medium;
        font-size: 14px;
        font-weight: $font-bold;
        line-height: 1.2;
        margin-bottom: 0;
        letter-spacing: 0.1em;
    }

    h2 {
        color: $gray-text-dark;
        margin-bottom: 15px;
    }

    //some overrides for the play button
    .play-btn-basic {
        z-index: 10; //overcome the main link
        // background-color: transparent;
        // border: none;
        // top: calc(50% - 48px);
        // left: calc(50% - 48px);
        font-size: 85px;
        i {
            // font-size: 48px;
        }
    }
}

.features-slider {
    position: relative;

    .slick {
        // hide all but first slide before slick initializes and then reveals them
        &:not(.slick-initialized){
            .slide {
                &:not(:first-child){
                    display: none;
                }
            }

        }
    }

    .slick-prev, .slick-next {
        display: block;
        background: none;
        border: 0;
        cursor: pointer;
        padding: 10px;
        transition: all .3s ease;
        z-index: 10;
        color: $white;
        position:absolute;

        font-size: 36px;
        top: calc(15% - 18px);
        @include xs-wide-and-up {
            top: calc(25% - 24px);
        }
        @include md-and-up {
            font-size: 48px;
            top: calc(50% - 24px);
        }

    }

    .slick-prev {
        left: 0;
        @include md-and-up {
            left: 20px;
        }
    }

    .slick-next {
        right: 0;
        @include md-and-up {
            right: 20px;

        }
    }

    .slick-dots {
        @include slick-dots-basic($violet, 0);
        position: absolute;
        right: 30px;
        bottom: 75px;
        @include sm {
            bottom: 30px;
        }
        @include md-and-up {
            bottom: 45px;
        }
    }
}

.home-feature-main {
    margin-bottom: $section-padding;

    .inner {
        position: relative;
    }

    @include md-and-up {

        .col-sm-9 {
            padding-left: $section-spacing;
        }

        .cta-col {
            position: relative;
            z-index: 2;
            >a {
                position: absolute;
                bottom: 15px;
                right: 50px
            }
        }

        .lead {
            color: #bbb ;
            font-size: 14px;
            font-weight: $font-bold;
            line-height: 1.2;
            margin-bottom: 0;
            letter-spacing: 0.1em;
        }

        h2 {
            font-size: 34px;
            line-height: 1.2;
            margin-bottom: 0px;
            margin-top: 0;
            a {
                color: $white;
            }
        }

        .summary {
            color: #cdcdcd;
            font-size: 16px;
            line-height: 1.4;
            p {
                color: #cdcdcd;
                font-size: 16px;
                line-height: 1.4;
            }

        }

        .arrow {
            color: $white;
            font-size: 22px;

        }

        .text-content {
            // padding-left: 20px;
            position: absolute;
            bottom: 15px;
            left: 50px;
            z-index: 2;
        }

        .feature-with-overlay .feature-overlay.bottom {
            bottom: 10px;
            z-index: 5;
        }
        .overlayed {
            overflow: hidden;
            &:after {
                content: "";
                background: transparent url("../img/daily-thumb-gradient.png") repeat-x top center;
                position: absolute;
                bottom: 0;
                height: 150px;
                width: 100%;
                z-index: 1;
            }
        }

    }

    @include sm {
        // .overlay.darken {
        //     box-shadow: none;
        // }
        // background: $white;
    }
    @include xs {
        padding: 0;
        img {
            margin-bottom: 20px;
        }
        .summary {
            font-size: 16px;
            line-height: 20px;
            margin-bottom: 20px;
        }
    }
}



.home-feature-secondary {

    section {
        background-color: $white;
    }

    .related-text {
        padding: 30px;
        @include xs {
            padding: 20px;
        }
        @include sm {
            padding: 20px;
        }
    }
    .snippet-type {
        font-size: 13px;
        @include xs {
            font-size: 11px;
        }
        @include sm {
            font-size: 11px;
        }
    }
    .snippet-title {
        font-size: 28px;
        line-height: 32px;
        margin-bottom: 10px;
        margin-top: 3px;
        @include xs {
            font-size: 24px;
            line-height: 28px;
        }
        @include sm {
            font-size: 24px;
            line-height: 28px;
        }
    }
    .related-post {
        margin-bottom: 0;
        padding-bottom: 0;
        .inner {
            position: relative;
        }
    }
    .summary {
        margin-bottom: 5px;
    }
}
