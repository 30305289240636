
// Bottom margin only

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6,
p {
    margin-top: 0;

    // last element shouldn't have margin to prevent doubling up
    &:last-child {
        margin-bottom: 0;
    }
}

//spacing between big items on page (modules)
.module {
    margin-bottom: $section-spacing-mobile;

    @include sm-and-up {
        margin-bottom: $section-spacing;
    }
}

%section-padding {
    padding: $section-spacing-mobile;

    @include sm-and-up {
        padding: $section-spacing-long $section-spacing;
    }
}

%section-small-padding {
    padding: 15px 10px;
}


.section-padded {
    padding-top: $section-spacing-mobile;
    padding-bottom: $section-spacing-mobile;

    @include sm-and-up {
        padding-top: $section-spacing;
        padding-bottom: $section-spacing;
    }
}

// used most often on sidebar blocks
// always apply to an element inside of a col-*, not on the col itself
.padded-block {
    padding: $grid-gutter-width / 2;
}

//stretch a div (e.g.: an image holder) end to end
.unpadded-horizontally {
    margin-left: -1 * $section-padding;
    margin-right: -1 * $section-padding;
}


//in mixin form
@mixin vertical-margin($v-margin: $section-padding) {
    margin-bottom: $v-margin;

    &:last-child {
        margin-bottom: 0;
    }
}


//remove gutters between columns
.no-gutter  {
    margin-left: 0;
    margin-right: 0;
    > [class*='col-'] {
        padding-right: 0;
        padding-left: 0;
    }
}

//reduce the gutter spacing by half
.half-gutter  {
    margin-left: 0;
    margin-right: 0;
    > [class*='col-'] {
        padding-left: $grid-gutter-width / 4;
        padding-right: $grid-gutter-width / 4;

        &:first-child {
            padding-left: 0;
            padding-right: $grid-gutter-width / 4;
        }
        &:last-child {
            padding-left: $grid-gutter-width / 4;
            padding-right: 0;
        }
    }
}

//place on parent
.flex-centered {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    -ms-flex-pack: center;
}

//ie fix. prevent text breaking out of flex
.flex-centered>p, .flex-centered>div, .flex-centered>h2 {
    width: 100%;
}

.flex-centered-sm {
    @include sm-and-up {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        -ms-flex-pack: center;
    }
}


.table-centered {
    display: table;
    margin: auto;
}

.table-centered-xs {
    @include xs {
        display: table;
        margin: auto;

    }
}

//for pages that you need to stretch (handy for body, page-container, ...)
.page-container {
    &.force-height {
        min-height: calc(100vh - 200px); // approximate space for header/footer
    }
}

//TODO: possible full width in mobile  for padded content box items?
.page-container .col-sm-12.content { //other candidates: .col-sm-12.module,  .col-sm-12.post
    @include xs {
        padding-left: 0;
        padding-right: 0;
    }
}
