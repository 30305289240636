.small-nav {
    li {
        a {
            color: $gray-text-dark;
            font-size: 16px;
            font-weight: $font-bold;
            line-height: 1.4;
            display: block;
            min-height: 64px;
        }
    }

    li:first-child { //title
        a {
            color: $gray-text-medium;
            font-size: 14px;
        }
    }
}
