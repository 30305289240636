
.navbar-nav.basic {
    display: flex;
    flex-wrap: wrap;
    @include xs {
        text-align: center;
        justify-content: center;
    }

    .menu-item {
        @include xs {
            // width: 50%;
            display: block;
            width: 100%;
            float: none;
            border-right: none;
            border-left: 3px solid $white;
        }


        @include sm-and-up {
            &:not(:last-child){
                border-right: 1px solid $white;
                margin-right: 10px;
            }
        }
    }

    a {
        color: $white;
        font-weight: 700;
        padding-right: 10px;
        &:hover {
            color: $brand-primary;
            text-decoration: none;
        }

        @include md-and-up {
            font-size: 24px;
        }
    }

}

.page-template-template-gala-landing, body.section-gala {

    .main-header {
        .header-title {
            display: block;
            position: relative;
            color: $brand-primary;
            letter-spacing: -0.5px;
            margin-bottom: 10px;
            @include xs {
                font-size: 28px;
                text-align: center;
            }

            @include sm-and-up {
                font-size: 48px;
                margin-left: -5px;
            }
            @include sm {
            }
            @include md {
                left: -8px;
                font-size: 48px;
            }
            @include lg {
                font-size: 60px;
                left: 0;
            }
            &:hover {
                text-decoration: none;
            }
        }

        .header-menu {



        }
    }

    .header-logos .fl {
        @include md-and-up {
            width: 100%;
        }
    }

    .header-main {
        @include sm-and-up {
            padding-left: 15px;
        }
    }

    // .left-logo .fl {
    //     position: relative;
    //     top: 25px;
    //     @include lg {
    //         top: 30px;
    //     }
    // }

    // .links-col .header-side-links .social-links {
    //     position: relative;
    //     top: 30px;
    // }


        // .main-header .social-links {
        //     &.mobile {
        //         width: auto;
        //         float: right;
        //         margin: 0;
        //         a {
        //             color: $white;
        //             &:not(:last-child){
        //                 margin-right: 5px;
        //             }
        //             &:hover {
        //                 text-decoration: none;
        //             }
        //         }
        //
        //     }
        // }
        //
        // .main-header .header-side-links {
        //     @include sm-and-up {
        //         left: 0 !important;
        //         width: 100% !important;
        //         top: 0 !important;
        //     }
        // }
        //
        // .header-logos {
        //     width: 100%;
        // }
        //
        // .left-logo a.fl {
        //     @include xs {
        //         float: left;
        //         width: auto ;
        //         position: static;
        //         width: 29%;
        //         max-width: 80px;
        //     }
        //
        //     @include md-and-up {
        //         width: 56%;
        //     }
        // }
        //
        //
        //
        // .header-title {
        //     a {
        //         position: relative;
        //         @include sm {
        //             top: 20px;
        //         }
        //         @include md-and-up {
        //             top: 10px;
        //         }
        //     }
        // }

}

.single {
    .header-container {
        margin-bottom: 30px;
    }
}

.page-template-template-gala-landing {




    .desktop-header {
        margin-bottom: 20px;
    }

    .hero {
        border: 6px solid $black;
    }

    .secondary {
        .blurb {
            &, p {
                color: $white;
            }
        }
    }

}
