.tt {
  position: relative;
}
.tt:before,
.tt:after {
  display: block;
  opacity: 0;
  pointer-events: none;
  position: absolute;
}
.tt:after {
  border-right: 6px solid transparent;
  border-top: 6px solid #f5f5f5;
  border-left: 6px solid transparent;
  content: '';
  height: 0;
  top: -10px;
  left: 20px;
  width: 0;
}
.tt:before {
  background: #f5f5f5;
  // border-radius: 2px;
  color: #333;
  content: attr(data-title);
  font-size: 13px;
  padding: 6px 10px;
  bottom: 28px;
  width: 250px;
  white-space: normal;
}

/* the animations */
/* faded */
.tt.faded:after,
.tt.faded:before {
  // transform: translate3d(0,10px,0);
  transition: all .15s ease-in-out;
}
.tt.faded:hover:after,
.tt.faded:hover:before {
  opacity: 1;
  transform: translate3d(0,0,0);
}
