// various displays of related posts

.snippet {

}


// Text on top of thumb, used on NYFF

.snippet-overlay {

    position: relative;


    &:hover {
        .hover-overlay {
            opacity: 1;
            &+.overlay-snippet-content {
                opacity: 0;
            }
        }
    }

    a:hover {
        text-decoration: none;
    }
    .overlay-headline {
        color: $white;
        font-size: 32px;
        font-weight: $font-black;
        line-height: 40px;
    }
    .overlay {
        position: absolute;
        bottom: 0;
        width: 100%;
        transition: opacity $transition-link-hover;
        padding: $grid-gutter-width / 2;
    }

    .hover-overlay {
        opacity: 0;
        color: $white;
    }
    @include sm-and-up {
        .overlay-headline {
            font-size: 22px;
            line-height: 26px;
            margin: 0;
        }
    }

    .secondary-features & {
        .overlay-headline {
            color: $white;
            line-height: 30px;
            text-transform: uppercase;
            font-weight: $font-black;

            @include xs {
                margin: 30px 0 60px;
            }

            @include sm {
                font-size: 16px;
            }
        }

        @include xs {
            .overlay {
                position: relative;
                padding: 0;
            }
        }
    }
}

// Related content inside of a single post body

.snippet-inline {

    &.snippet-full {
        background: $gray;
        padding: $grid-gutter-width;
    }

    &.snippet-right {
        float: right;
        margin: 20px;
        width: 200px;
        border: 1px solid #efefef; // todo: delete, just to show it currently

        @include lg {
            margin-right: -80px;
        }
    }

}
