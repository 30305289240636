.header-ads {
    // background: #333333;
    // background: darken(#ffffff, 1%);
    background-color: $off-white;
    border-bottom: 1px solid rgba(0,0,0,0.08);

    @include sm-and-up {
        padding: 10px $grid-gutter-width / 2;
    }

    @include lg {
        padding: 15px $grid-gutter-width / 2;
    }

    // force responsive to adjust
    .ad-billboard {

        @include breakpoint-max(1024px) {
            overflow: hidden; //prevent add widget iframes breaking out of container
            height: 90px !important;
            width: 728px !important;
        }
    }

    .ad-leaderboard, .ad-billboard {
        @include xs {
            overflow: hidden; //prevent add widget iframes breaking out of container
            height: 50px !important;
            width: 320px !important;
        }

        > div {
            @include xs {
                height: 50px !important;
                width: 320px !important;
            }
        }
    }

    .ad {
        margin-bottom: 0;
    }
}

.header-app-banner {
    background-color: $black;
    padding-top: 10px;
    margin-bottom: 10px;
}

.main-header {
    background-color: $white;
    border-bottom: 1px solid rgba(0,0,0,0.08);
    a {
        transition: $transition-link;
    }

    // reset bootstrap
    .navbar {
        background-color: inherit;
        border: none;
        border-radius: 0;
        margin-bottom: 0;
        min-height: initial;
    }

    .navbar-collapse {
        padding-left: 0;
        padding-right: 0;
    }

    .navbar-justified {
        display: flex;
        align-items: center;
        width: 100%;
        > li {
            // border: 1px solid black;
            // min-width: 14.25%;
            @include sm {
                padding-right: 5px;
            }
            @include md {
                padding-right: 8px;
            }
            @include lg {
                padding-right: 12px;
            }
        }
    }
}

.header-email-signup-text {
    font-weight: $font-semibold;
}

.header-email-signup {
    display: inline-block;
    margin: 0 0 10px 15px;
    @include sm-and-up {
        margin-bottom: 0;
    }

    input {
        color: $text-color;
    }

    .btn {
        display: block;
        width: 100%;

        &:focus {
            background-color: $violet;
        }
    }

    .inner {
        @include sm-and-up {
            display: flex;
            flex-wrap: wrap;
            margin: 0;

            .form-group {
                flex: 2;
                margin: 0;
                padding-right: 15px;
            }

            .btn, .form-control[type="email"] {
                padding: 6px;
                @include md-and-up {
                    padding: 6px 16px;
                }
            }

            .form-control[type="email"] {
                max-width: 100px;
                @include sm-and-up {
                    display: block;
                    height: 100%;
                }
                @include md-and-up {
                    max-width: 180px;
                }

            }

            .btn {
                width: auto;
                flex: 1;
            }

        }
    }

}


// universal

.header-search {
    clear: both;
    float: right;
    max-width: 250px;
    @include sm-and-up {
        margin: 0;
    }

    input {
        background: inherit;
        border: 0;
        box-shadow: none;

        &,
        &::placeholder,
        &:focus {
            color: $gray-text-dark;
        }
    }

    button {
        background: inherit;
        border: 0;
        box-shadow: none;
    }

    @include md-and-up {
        display: flex;
        align-items: center;
    }

    i {
        cursor: pointer;
    }
}

//only visible in mobile
.mobile-main-nav-header {
    padding: 10px 0;

    .left-logo {
        float: left;
        max-width: 160px;
        @include sm-and-up {
            width: 80%;
            max-width: 300px;
        }
    }

    .middle-logo {
        float: left;
        width: 65%;
        padding: 0 10px;

        a {
            margin: 0 auto;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
        }

        img {
            max-width: 90%;
            @media screen and (min-width: 400px) and (max-width: $screen-sm-min){
                max-width: 90%;
            }
        }
    }

    .right-hamburger {
        float: right;
    }

    .hamburger {
        padding: 5px 10px;
        background-color: $off-white;
        border: 1px solid rgba(0, 0, 0, .19);
        border-radius: 6px;
        width: 100%;
        color: $gray-text-dark;
        float: right;
        // top: calc(50%  - 12px);
        margin-top: 13px;
        position: relative;
        &:hover {
            text-decoration: none;
        }
        @media screen and (min-width: 400px) and (max-width: $screen-sm-min){
            padding: 5px 10px;
            top: calc(50%  - 17px);
        }

        .fa-bars {
            font-size: 12px;
            position: relative;
            left: 2px;
            top: 1px;
            @media screen and (min-width: 400px) and (max-width: $screen-sm-min){
                font-size: 24px;
            }
        }
    }
}


.desktop-header {
    padding-top: 10px;
    padding-bottom: 10px;
    .header-left {
        float: left;
        @include sm {
            max-width: 90px;
        }
        @include md {
            max-width: 250px;
        }
        @include lg {
            width: 280px;
        }

        img {
            width: 100%;
        }



        .header-logo {
            display: block;
            width: 100%;
            padding-top: 7px;
            padding-bottom: 7px;
        }
    }

    .header-right {
        float: right;
        @include sm-and-up {
            padding-left: 20px;
            width: calc(100% - 95px);
        }
        @include md {
            width: calc(100% - 250px);
        }
        @include lg {
            padding-left: 30px;
            width: calc(100% - 280px);
        }
    }
}

//only visible in sm-and up
.header-social-nav {
    padding-top: 10px;
    padding-bottom: 0;
    font-size: 16px;
    @include sm-and-up {
        padding-top: 5px;
    }

    .arrow {
        font-size: 14px;
        line-height: 19px;
        font-family: $font-sans;
        @include md-and-up {
            font-size: 16px;
        }
        i {
            position: relative;
            top: 2px;
        }
        &:hover {
            background: none;
            text-decoration: none;
        }
    }

    .social-links {
        position: absolute;
        top: 0;
        right: 0;
        a {
            color: $gray-text-dark;
            margin-left: 5px;
            padding: 5px 8px;
            display: inline-block;

            @include md {
                padding: 5px 4px;
            }

            &:hover {
                background: transparent;
                text-decoration: none;
            }
            &.cart {
                background: #e5e5e5;
            }
            &.app-link {
                font-size: 1.5em;
                position: relative;
                top: 3px;
            }
        }
        strong {
            font-size: 13px;
        }
    }

    a:hover {
        background-color: $gray;
    }

}

.header-secondary-nav {
    padding-top: 10px;
    @include sm-and-down {
        margin-top: 10px;
    }

    // &:after{
    //     content: "";
    //     display: block;
    //     border-bottom: 2px solid $gray;
    //     margin-right: $grid-gutter-width / 2;
    //     margin-left: $grid-gutter-width / 2;
    //     margin-top: 10px;
    // }

    .fa-search {
        transform: rotate(90deg);
    }

    li:first-child {
        a {
            padding-left: 5px;
            margin-left: -5px;
        }
        .dropdown-menu {
            margin-left: -5px;
        }
    }
    li:hover {
        >a {
            color: $violet;
        }
    }
    li>a {
        color: $gray-text-dark;
        font-weight: 400;
        padding: 0 3px;
        font-size: 12px;
        line-height: 30px;
        border-radius: 0;
        @include md {
            padding: 0 5px;
            font-size: 14px;
        }

        @include lg {
            padding: 0 10px;
            font-size: 18px;
            line-height: 42px;
        }
        &:hover {
            background: none;
            // color: $gray-text-dark;
            color: $violet;
        }
    }

    .dropdown-menu {
        padding: 0;
        border: 0;
        li {
            padding: 10px;
            &:hover, &.open {
                background-color: $gray;
                >a {
                    color: $violet
                }
            }
        }
        a {
            color: #454545;
            font-size: 15px;
            line-height: 20px;

        }
    }
}

// The main nav, visible in mobile as well
.desktop-main-nav.navbar {
    border-top: 1px solid rgba(0, 0, 0, .05);
    padding: 0;
    position: relative;
    left: -5px; //left align with nave above it

    li.top-level:first-child {
        a {
            // padding-left: 0;
            // &:hover {
            //     padding-left: 5px;
            //     @include md {
            //         padding-left: 7px;
            //     }
            //     @include lg {
            //         padding-left: 25px;
            //     }
            // }
        }
    }

    li:hover, li.open {
        >a {
            color: $violet;
        }
    }
    li>a {
        color: $gray-text-dark;
        font-weight: $font-bold;
        font-size: 12px;
        padding: 10px 5px;
        letter-spacing: 0.1rem;
        text-transform: uppercase;
        @include sm {
            font-size: 11px;
        }
        @include md {
            font-size: 13px;
            line-height: 15px;
        }

        @include lg {
            font-size: 17px;
            line-height: 18px;
        }


    }
    .dropdown-menu {

        li>a {
            text-transform: none;
            letter-spacing: 0;
            padding: 10px;
            @include lg {
                font-size: 14px;
                line-height: 18px;
            }

        }
    }


    span.caret {
        display: none;
    }

    .yamm-dropdown {
        left: 0;
    }
    .flyout-submenu {
        border-right: 1px solid rgba(0,0,0,0.1);
        padding: 40px;
        a {
            font-size: 14px;
            letter-spacing: 0;
            // font-weight: $font-semibold;
            line-height: 18px;
            color: $gray-text-dark;

            &.header-link {
                font-size: 14px;
                font-weight: $font-bold;
                line-height: 33px;
                color: $gray-text-medium;
            }
            &:hover {
                background: none;
                color: $violet;
            }
        }
        li {
            margin-bottom: 5px;
        }
        .section {
            color: $gray-text-medium;
            font-size: 14px;
            letter-spacing: 0.1em;
            font-weight: $font-bold;
            // margin-bottom: 10px;
            padding-bottom: 10px;
        }
    }

    .flyout-description {
        padding: 40px;
        p {
            font-size: 16px;
            line-height: 22px;
        }
    }

    .dropdown-menu {
        border: 0;
        padding: 0;
        border-radius: 0;
        .yamm-content {
            // padding: 0;
        }
    }
}
.films-series-nav-content {
        padding: 30px;
        width: 100%;
        // li:first-child {
            a {
                &:hover {
                    padding: 0;
                }
            }

        // }

        // display: none;
        .more {
            margin-left: 30px;
        }
        .item {
            img {
                margin-bottom: 10px;
            }
            a {
                color: $gray-dark;
            }
        }
        .snippet-type {
            color: $gray-text-medium;
            font-size: 11px;
            line-height: 11px;
            font-weight: $font-bold;
            letter-spacing: 0.1em;
        }
    }


//
// mobile menu
//

// http://stackoverflow.com/questions/19084168/how-to-keep-panel-height-fixed-with-scroll-if-content-overflow-for-jquery-mobile
.primary-mobile-nav {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    z-index: 900;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;

    padding: 30px 30px;
    background-color: rgba($white-off, .98);
    color: $gray-text-dark;

    .scrollable {
        background-color: rgba($white-off, .98);
    }

    // Override bootstrap
    .navbar-nav {
      margin: 0;
    }
    &.collapsing {
        transition-duration: (.1s);
    }

    .nav-closer {
        position: absolute;
        top: 5px;
        right: 5px;
        z-index: 1000;
        padding: 20px;
        display: inline-block;
        cursor: pointer;

        &,
        &:hover {
            font-size: 24px;
            color: $black;
            text-decoration: none;
        }
    }

    ul {
        padding: 0;
    }

    a {
        color: $gray-text-dark;
        font-size: 24px;
        line-height: 32px;
        margin-bottom: 12px;
        display: block;
    }

    .close {
        opacity: 1;
        position: absolute;
        top: 30px;
        right: 30px;
    }

    .primary-menu {
        font-weight: $font-bold;
        a {
            letter-spacing: 0;
        }
    }

    .secondary-menu {
        font-weight: $font-semibold;
        padding-top: 30px;
        border-top: 1px solid rgba(0,0,0,0.1);
        margin-top: 30px;
        li {
            display: block;
            width: 100%;
        }
    }

    .social-links {
        margin-bottom: 10px;
        max-width: 480px;
        a {
            font-size: 32px;
        }

        @include xs {
            width: 100%;
            a {
                width: 25%;
                float: left;
                text-align: center;
                font-size: 20px;
                letter-spacing: 0;
                &.login {
                    width: 50%;
                }
            }
            .login-link {
                display: block;
                font-size: 20px;
            }

        }
    }

    .sign-up-nav {
        @include xs {
            clear: both;
            margin-top: 30px;
            display: block;
        }
    }

    .input-group {
        max-width: 480px;

        @include xs {
            margin-top: 20px;
            border-bottom: 1px solid rgba(0,0,0,0.1);
        }
    }
    .social-links {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    form {
        margin-bottom: 20px;

        input,
        button {
            font-size: 19px;
            background-color: $off-white;
            color: $gray-text-dark;
            font-style: italic;
            padding-left: 0;
        }

        .btn-default {
            position: relative;
            top: 5px;
            transform: rotate(90deg);
        }

        input,
        .btn {
            border: 0;
            box-shadow: none;
        }
    }

    .arrow {
        white-space: nowrap;
    }

    .header-email-signup {
        display: block;
        max-width: 480px;
        margin: 15px 0;

        .inner {
            display: flex;
            flex-wrap: wrap;
            margin: 0;

            .form-group {
                flex: 3;
                margin: 0 15px 0 0;
            }

            .form-control {
                height: 100%;
                border-bottom: 1px solid rgba(0,0,0,0.1);
            }

            .btn {
                flex: 1;
                background-color: $brand-primary;
                color: $white;
                padding: 8px 12px;
            }
        }
    }
}

.algolia-autocomplete {
    width: auto;
    @include lg {
        width: 100%;
    }
    .aa-input,
    .aa-hint {
        width: 100%;
        max-width: 90px;
        min-height: 30px;
        text-indent: 10px;
        background-color: #fbfbfb;
        @include lg {
            max-width: 180px;
        }
    }
    .aa-hint {
        color: #999;
    }
    .aa-dropdown-menu {
        background-color: #fff;
        border-top: none;
        border: 1px solid #999;
        width: 300px;
        left: inherit !important;
        right: -31px !important;
        .aa-suggestion {
            cursor: pointer;
            &.aa-cursor {
                background: #f8f8f8;
            }
            em {
                font-weight: bold;
                font-style: normal;
            }
        }
        > div {
            -webkit-column-break-inside: avoid;
            page-break-inside: avoid;
            break-inside: avoid-column;
            display: table;
            width: 100%;
        }
    }
    .category {
        text-align: left;
        background: #efefef;
        padding: 10px 5px;
        font-weight: bold;
    }
    strong {
        border-bottom: 1px solid transparentize( #999, 0.9 );
        display: block;
        margin: 5px 10px;
        padding: 5px;
        text-transform: uppercase;
    }
    .result {
        padding: 5px 15px;
    }
}

.result {
    p {
        margin: 0;
    }
    &__type {
        color: transparentize( $gray-text-dark, 0.6 );
        font-size: 12px;
    }
    &__title {
        font-size: 14px;
    }
    em {
        border-bottom: 2px solid;
    }
}

.header-nav-banner-message {
        display: table;
        padding: 8px 12px;
        margin: 15px auto;
        font-weight: 600;
        color: $violet; //$brand-primary-nyff2021
        border: 1px solid $violet;

        a {
            color: $violet;
            text-decoration: underline;
        }
        @include sm-and-up {
            font-size: 24px;
        }
        @include md-and-up {
            margin: 30px auto;
            font-size: 30px;
        }

}
