// Todo, make this a dedicated SCSS file so that it can live on even if the main site changes in the future

$nyff2015-black: #252525;
$nyff2015-gray: #778794;
$nyff2015-gray-off: #3B3B3B;
$nyff2018-orange: #ff7a17;
$nyff2016-tan: $nyff2018-orange; //used with links (was originally db905e, then back to $violet)
$nyff2016-tan-hover: darken($nyff2018-orange, 10%);
$nyff2016-gray-off: #1a1a1a;
$nyff2015-gray-hover: darken($nyff2015-gray, 10%);
$nyff2015-header-height: 180px;

.section-nyff2020 {
    background-color: $black;
    // background-image: url(/wp-content/uploads/2017/08/nyff2017-stars-bg-2.jpg); //background art image can be placed here
    background-position: center 20px;
    background-repeat: no-repeat;
    background-attachment: fixed;

    .desktop-header, .desktop-header .container {
        position: relative;
    }

    .header-ads-enabled {
        position: relative;
    }

    .ad-billboard {
        @include md-and-up {
            margin-bottom: 15px;
        }
    }

    .desktop-header {
        .secondary-nav {
            position: relative;
            @include md-and-up {
                margin-top: -10px;
            }




            .navbar-nav>li>a {
                padding-top: 5px;
                padding-bottom: 5px;
            }
        }
    }

    .page-container {
        // background: $nyff2015-black;
        color: $white;
        .content {
            .content-box {
                color: $gray-dark;
                padding: 30px;
            }
        }

    }
    &.single-post {
        .next-to-header-logo {
            font-size: 12px;
            font-weight: $font-bold;
        }
        .content {
            .content-box {
                color: $gray-dark;
                padding: 0
            }
        }

    }
    .main-footer {
        background: $white;
    }

    .btn-primary {
        background: $brand-primary;
        border: none;
        font-family: $font-headline;
        text-transform: uppercase;

        &:hover {
            background: darken($brand-primary, 10%);
        }
    }

    h4 {
        font-family: $font-headline;
        font-size: 20px;
        font-weight: $font-bold;
        line-height: 22px;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        position: relative;
        @include xs {
            font-size: 20px;
            line-height: 24px;
        }
        @include sm {
            font-size: 18px;
        }
        .more {
            display: inline;
            font-size: 14px;
            line-height: 18px;
            color: $brand-primary;
            font-weight: 600;
            text-transform: none;
            letter-spacing: 0;
            font-family: $font-sans;
            @include md-and-up {
                position: absolute;
                right: 0;
                bottom: 0;
            }
        }
    }

    // todo: abstract to a hero component
    @include xs {
        .hero-wrap {
            position: relative;
            .video {
                position: absolute;
                width: 100%;
                z-index: 90;
                top: 50%;
                transform: translate(0, -50%);
                text-align: center;
                font-size: 100px;
                color: $white;
            }
        }
    }

    .secondary-features {
        padding-bottom: 40px;
        .image-holder {
            position: relative;
        }

        .video {
            font-size: 85px;
            top: calc(50% - 42px);
            transform: none;
        }
    }

    .video {
        color: $white;
        font-size: 115px;
        opacity: 0.8;
        &:hover, &:focus {
            opacity: 1;
            text-decoration: none;
        }

    }

    .hero-wrap {
        &:after {
            content: "";
            background: transparent url('../img/daily-thumb-gradient.png') repeat-x bottom center;
            position: absolute;
            bottom: 0;
            height: 200px;
            width: 100%;
        }
    }
    .hero-text {
        margin: 15px 30px;
        @include sm-and-up {
            margin: 0;
            text-align: center;
        }

        .video {
            @include xs {
                display: none;
            }
        }

        h3 {
            color: $white;
            font-weight: $font-bold;
            font-size: 20px;
            @include sm-and-up {
                font-size: 32px;
            }
            line-height: 1.3;
            text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
            a {
                color: inherit;
            }
            @include sm {
                font-size: 28px;
                line-height: 32px;
                width: 70%;
                margin: 0 auto 20px;
            }

            @include md {
                font-size: 38px;
                line-height: 1;
                width: 58%;
                margin: 0 auto 20px;
            }
            @include lg {
                font-size: 44px;
                width: 58%;
                margin: 0 auto 20px;
            }
        }

        .cta {
            color: $white;
            font-size: 16px;
            line-height: 26px;
            @include sm {
                font-size: 18px;
                line-height: 20px;
            }
            @include md-and-up {
                &, i {
                    font-size: 26px;
                }
            }
        }

        .hero-feature-subtitle {
            font-size: 24px;
            text-transform: initial;
        }

        .hero-feature-subtitle {
            font-size: 24px;
            text-transform: none;
        }

        .hero-feature-summary {
            color: $white;
            @extend %font-sans;
            font-size: 20px;
        }
    }

    .header-ads-enabled+div+div+.hero {
        .video-lightbox {
            @include md {
                top: calc(50% + 45px);
            }
            @include lg {
                top: calc(50% + 75px);
            }
        }
    }

    &.header-over-hero {
        .hero {
            // margin-bottom: 60px;
            max-width: 1600px;
            margin: 0 auto 60px;


        }
        @include sm-and-up {
            .main-header {
                border: 0;
            }

            .hero {
                position: relative;
                width: 100%;
                margin-bottom: 40px;
                img {
                    width: 100%;
                }
                &:after {
                    content: "";
                    background: transparent url('../img/nyff-hero-gradient.png') repeat-x top center;
                    position: absolute;
                    top: 0;
                    height: 500px;
                    width: 100%;
                }
            }

            .hero-text {
                position: absolute;
                width: 100%;
                z-index: 90;
                bottom: 50px
            }
        }
        @include sm {
            .main-header {
                position: relative;
                margin-bottom: 0;
            }
        }

        // fix for wp admin bar
        &.admin-bar .main-header {
            top: 32px;

            @include xs {
                //top: 46px;
                top: 0px;
            }
        }
    }


    .header-ads {
        position: relative;
        // top: -20px;
        background-color: $black;
    }

    .js-navigation-toggle {
        color: $white;
        font-weight: $font-bold;
        opacity: 0.9;
        right: 15px;
        z-index: 1;
        font-size: 13px;
        @include xs-wide-and-up {
            font-size: 14px;
        }

        &:hover {
            text-decoration: none;
            opacity: 1;
        }

        &:focus {
            text-decoration: none;
        }

        span {
            font-size: 16px;
            position: relative;
            top: -1px;
        }

        i {
            position: relative;
            font-size: 16px;
            top: 2px;
            margin-left: 5px;


        }
    }

    h4.title {
        font-family: $font-headline;
    }

    .main-header {
        background: transparent;
        margin: 0;
        @include xs {
            margin-top: 15px;
        }

        .header-title {
            margin: 30px 0 10px;
            @include sm-and-up {
                margin: 0;
            }
            @include md-and-up {
                margin: 0 auto;
                max-width: 90%;
                position: relative;
                left: 10px;
            }
            a {
                color: inherit;
                &:hover {
                    text-decoration: none;
                }
            }

            .side-logo {
                &:hover {
                    text-transform: none;
                }
                color: $white;
                display: flex;
                align-items: center;
                @include xs {
                    flex-wrap: wrap;
                }

                img {
                    flex: 1.75;

                    @include sm {
                        flex: 1;
                        max-width: 60%;
                    }
                    @include md-and-up {
                        margin-left: -45px;
                    }

                }
                time {
                    text-transform: none;
                    flex: 1;
                    font-weight: 600;
                    font-size: 18px;

                    @include sm-and-up {
                        text-align: left;
                        padding-left: 15px;
                        font-size: 16px;
                    }
                    @include md-and-up {
                        font-size: 24px;
                    }
                    @include lg {
                        font-size: 28px;
                    }
                }
            }
        }

        .headline-mobile-title {
            margin-bottom: 20px;
        }

        .date {
            color: $white;
        }


        .header-logo {
            padding-top: 0px;
        }

        .date {
            text-transform: uppercase;
            font-weight: $font-black;
            font-size: 15px;
            line-height: 15px;
            letter-spacing: 0.1em;
        }

        .tagline-wrap {
            @include sm {
                position: relative;
                top: 20px;
            }
            @include md-and-up {
                // position: absolute;
                // bottom: 14px;
                  // margin-bottom: 14px;
                  // margin-top: 8px;
                  margin: 0;
            }
        }

        .title {
            font-size: 30px;
            font-weight: $font-black;
            text-transform: uppercase;
            margin-top: 7px;

            @include sm-and-up {
                line-height: 0;
            }
            .number {
                color: $nyff2015-gray;
            }

            .sup {
                color: $nyff2015-gray;
                font-size: 20px;
                position: relative;
                top: -5px;
            }
        }

        .links-col {
            @include xs {
                position: static;
            }
        }
        .header-side-links {

            .menu-links {
                display: flex;
                flex-wrap: wrap;
                flex-direction: row;
                justify-content: flex-end;
                color: $white;

                @include sm-and-up {
                    margin-bottom: 10px;
                }

                .divider {
                    padding: 0 5px;
                }
                a, span {
                    @include md-and-up {
                        font-size: 16px;
                    }
                }

            }
            .social-links {
                // float: right;
                display: flex;
                justify-content: flex-end;

                a {
                    @include md-and-up {
                        font-size: 16px;
                    }
                }

            }

            a.login {
                // margin-left: 2px;

                // font-size: 15px;

                &.mobile {
                    font-size: 15px;
                    margin-top: 3px;
                    @include xs-wide-and-up {
                        display: none;
                    }
                }
            }

            @include xs {
                position: absolute;
                top: 0;
                right: 15px;
                display: flex;
                flex-direction: column;
                text-align: center;

                .social-links {
                    // display: none;
                }

                .js-navigation-toggle {
                    margin-bottom: 10px;
                }
            }

            @include xs-wide {
                .social-links {
                    display: block;
                    margin-bottom: 10px;
                }
            }
            @include sm-and-up {
                position: relative;
                // top: 10px;
                // width: calc(100% + 45px);
                // left: -30px;


            }
            @include md-and-up {
                // float: right;
                // width: calc(100% + 90px);
                // left:  -100px;
                margin: 0px 0 20px;



            }
            @include lg {
                // width: calc(100% + 60px);
                // left: -54px;

                .social-links {
                    font-size: 1.4rem;
                }
            }


            a {
                color: $white;
                font-weight: 700;
                letter-spacing: 0.1rem;
                opacity: 0.9;

                &:hover {
                    text-decoration: none;
                    opacity: 1;
                }
            }


            // .header-menu-toggle {
            //     margin-left: 12px;
            // }

            .social-links {
                a {
                    &:not(:last-child){
                        padding-right: 5px;
                        &.hashtag {
                            padding-right: 10px;
                        }
                    }
                }

            }
        }


        .signup-holder {
            &.mobile {
                .inner {
                    display: flex;
                }

                .header-email-signup {
                    margin: 10px 0 10px;
                }
            }
            &.desktop {
                position: relative;
                margin-top: 20px;
                min-height: 100px;

                .header-email-signup {
                    margin: 0;
                    .inner {
                        @include md {
                            flex-direction: column;
                            .form-group {
                                padding-right: 0;
                                margin-bottom: 10px;
                            }
                        }
                        @media screen and (min-width: 1150px){
                            flex-direction: row;
                            .form-group {
                                padding-right: 5px;
                                margin-bottom: 0;
                            }
                        }

                    }
                }
            }
        }
        .header-email-signup-text {
            color: $white;
            float: right;
        }

        .form-group {
            padding-right: 5px;
            @include xs {
                margin-bottom: 0;
            }
        }
    }

    .header-logos {
        position: relative;
        margin: 0;
        // top: 14px;
        @include xs {
            margin-bottom: 10px;
        }
    }

    [data-mh="nyff-header"] {
        @include sm-and-up {
            display: flex;
            align-items: center;
            width: 100%;
        }

        &.header-title {
            justify-content: center;
            >a {
                @include sm-and-up {
                    flex: 2;
                }
            }
        }

        &.links-holder {
            @include sm-and-up {
                align-items: center;
                >.inner {
                    max-height: 62%;
                    flex: 1;
                }

            }
        }

    }

    .left-logo {
        margin-bottom: 10px;


        .fl {

            float: left;
            margin-right: 15px;
            width: 110px;
            @include sm-and-up {
                width: 156px;
            }

        }


    }
    .social-nav {
        clear: both;

        .social-links {
            color: $white;
            margin-bottom: 15px;
            .social-list {
                display: flex;
                flex-direction: row;
                justify-content: center;
                a {
                    font-size: 14px;
                    text-align: center;
                    width: 20%;
                    font-weight: 500;
                    @include xs {
                        font-size: 18px;
                    }
                }
            }
            a {
                color: $white;
                padding: 5px 2px;
                margin-left: 4px;
            }
            .icon {
                &:hover {
                    color: $nyff2015-gray;
                    text-decoration: none;
                }
            }

            .btn-primary {
                border-radius: 0;
                font-size: 14px;
                letter-spacing: 0.1em;
                padding: 7px 10px;
                margin-top: 25px;
                line-height: 14px;
                font-weight: $font-bold;
                &:hover {
                    background: $nyff2015-gray-hover;
                }
            }
        }
    }

    .nav-wrap {
        @include sm {
            width: 100%;
            float: none;
        }
        .main-nav-wrap {

        }
    }

    .header-search {
        margin: 5px 0 0 0;
        width: 100%;
        border-bottom: 1px solid rgba(255,255,255,0.3);
        margin-bottom: 30px;
        max-width: none;
        // todo: Add search icon next to social and expand out on small screens
        @include sm {
            display: none;
        }
        input {
            &,
            &::placeholder,
            &:focus {
                color: $white;
                font-style: italic;
            }
        }

        .btn {
            color: $white;
            &:hover,
            &:active {
                color: $nyff2015-gray-hover;
                background: transparent;
            }
        }
        @include xs {

            input {
                &,
                &::placeholder,
                &:focus {

                }
            }
            .btn {

            }
        }

        #auto-search {
            margin: 0;
            padding-left: 0;
            max-width: 80%;
            display: block;
            color: $white;
            background-color: $text-color;
        }
    }


    //
    // Homepage
    //

    .nyff-header-light {
        margin-bottom: 30px;
        h4 {
            margin-bottom: 15px;
            @include md-and-up {
                font-size: 36px;
                font-family: $font-headline;
            }
        }
        &, a {
            color: $white;
        }
        .more {
            font-weight: 700;
            i {
                position: relative;
                top: 2px;
            }
        }

        .blurb, a {
            font-size: 12px;
            @include md-and-up {
                font-size: 18px;
            }
        }
    }


    .happening {
        background-color: $gray-dark;
        margin-bottom: 30px;
        padding-top: 30px;
        padding-bottom: 30px;
        @include md-and-up {
            padding-top: 60px;
            padding-bottom: 60px;
            margin-bottom: 60px;
        }
    }

    .full-width-posts {
        padding: 30px 0 0;
        @include md-and-up {
            width: 100vw;
            position: relative;
            left: 50%;
            right: 50%;
            margin-left: -50vw;
            margin-right: -50vw;
        }

        .gallery-item-controls {
            background-color: $gray-dark;
            opacity: 1;
        }
    }
    .section-grid {
        margin-bottom: 30px;
        h4 {
            color: $white;
        }
        h3 {
            font-family: $font-sans;
            text-transform: uppercase;
            letter-spacing: 0.1em;
            font-weight: 900;
            line-height: 24px;
            font-weight: normal;
            font-size: 18px;
            @include xs-wide-and-up {
                font-size: 24px;
            }
            @include md-and-up {
                font-size: 18px;
            }
        }

        //less spacing in the sections grid
        .featured-sections, .secondary-sections {
            margin-bottom: 6px;
            margin-left: -6px;
            margin-right: -6px;
            > [class*='col-'] {
                padding-right: 6px;
                padding-left: 0px;
                &:first-child {
                    padding-left: 6px;
                }
                &:last-child {
                    padding-right: 12px;
                }
            }
        }
        .featured-sections {
            h3 {
                font-size: 24px;
                @include sm-and-up {
                    font-size: 32px;
                }
            }
        }
        .snippet-overlay {
            background: $black;
            z-index: 1;
            img {
                opacity: 0.9;
                transition: opacity .3s;
            }
            .overlay-snippet-content {
                @extend .flex-centered;
            }
            &:hover {
                img {
                    opacity: 0.4;
                }
            }
            .hover-overlay {
                @extend .flex-centered;
            }
            @include xs {
                margin-bottom: 30px;
            }

        }
        @include sm-and-up {
            .nopadding {
                // resets default negative padding
                margin-left: 0;
                margin-right: 0;

                > .col-sm-4 {
                    padding: 0;
                }
            }
        }
    }

    .section-instagram-slider {
        overflow: hidden;
        min-height: 320px;
        background: black;

        .title {
            color: $white;
            padding-bottom: 0;
        }
    }

    .secondary-features {
        .pre-sections {
            .overlay {
                position: relative;
                padding: 0;
                margin-top: 10px;
            }
            .overlay-headline {
                font-family: $font-sans;
                font-size: 20px;
                line-height: 1.2;
                font-weight: 700;
                text-transform: none;
            }
        }
        .overlay-headline {
            font-size: 24px;
        }
        h3 a {
            color: $white;
        }
    }

    .nyff-anouncements {
        @include md-and-up {
            margin-top: -100px;
        }
        background: $white;

        .item {
            margin-bottom: 20px;
            &, a {
                color: $brand-primary;
            }
        }
        h3 {
            font-size: 20px;
            line-height: 24px;
            font-weight: $font-bold;

        }
        .date {
            font-style: italic;
            color: #959595;
        }
        .more {
            color: $brand-primary;
        }
    }

    // new to 2020. no filmcomment so giving sections at bottom of nyff2020 page more room
    .section-page-touts {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: center;

            .section-item {

                @include xs {
                    width: 100%;
                }
                @include sm-and-up {
                    &:not(:last-child){
                        margin-right: 8px;
                        margin-bottom: 5px;
                    }
                }
                @include sm {
                    width: 33%;
                }
                @include md-and-up {
                    // width: 33%;
                    flex: 1;
                }

        }
    }

    .nyff-daily {
        color: $white;
        margin-bottom: 5px;
        @include md-and-up {
            padding-top: 0;
        }

        .nyff-header-light {
            @include md-and-up {
                margin-top: -6px
            }
        }

        .filmcomment-logo {
            margin-bottom: 25px;
            max-width: 220px;
            @include sm-and-up {
                max-width: 280px;

            }
        }

        .daily-meta-header {
            font-size: 12px;
            line-height: 13px;
            margin-bottom: 7px;
            h6 {
                margin-bottom: 0;
            }
            h5 {
                font-weight: $font-bold;
            }
        }

        .more {
            color: $brand-primary;
            font-size: 14px;
            text-decoration: none;
        }

        .item {
            margin-bottom: 30px;

            &, a {
                color: $white;
            }

            .image {
                float: right;
                position: relative;
                width: 35%;

                i {
                    position: absolute;
                    top: 0;
                    right: 0;
                    background: $nyff2015-black;
                    padding: 3px;
                }
            }

            .text {
                float: left;
                width: 60%;
                h3 {
                    font-size: 18px;
                    line-height: 1.2;
                    margin-bottom: 4px;
                }
                .blurb {
                    font-size: 14px;
                    color: #c6c6c6;
                }


            }
        }
        h4 {
            margin: 20px 0 30px
        }
        .fa-chevron-right {
            position: relative;
            top: 3px;
        }
    }
    .global-sponsors {
        background: $white;
        padding-bottom: 30px;
        margin-bottom: 0;
    }
    .main-footer {
        margin-top: 0;
        padding-top: 20px;
    }
    &.single-series {

        .films-group>h3 {
            border-top: 1px solid rgba(0, 0, 0, 0.1);
            padding-top: 20px;
        }

        .film     {
            margin-bottom: 30px;
            @include sm-and-up {
                padding-bottom: 30px;
                border-bottom: 1px solid rgba(0,0,0,0.1);
            }
            &:last-child {
                border: 0;
            }

            p {
                font-size: 14px;
                line-height: 18px;
            }
            .callout {
                margin-bottom: 5px;
            }
        }
    }

    .teaser-gallery {
        figure {
            &:before {
                content: "";
                background: transparent url('../img/daily-thumb-gradient.png') repeat-x bottom center;
                position: absolute;
                bottom: 0;
                height: 150px;
                width: 100%;
                z-index: 1;
            }
            .gallery-item-controls {
                position: relative;
                z-index: 2;
                h2 {
                    font-weight: $font-bold;
                    @include md-and-up {
                        font-size: 20px;
                    }
                }
                time {
                    font-size: 13px;
                }
            }
            .video-lightbox {
                z-index: 2;
            }
            h2 {
                font-size: 16px;
                line-height: 20px;
                margin-bottom: 0;
            }

        }
        .icon-link {
            position: absolute;
            top: 0;
            right: 0;
            padding: 3px;
            z-index: 2;
            display: none;
        }

        a.overlay {
            z-index: 2;
        }
    }

    .teaser-video {
        position: relative;
        .video-lightbox {
            opacity: 1;
            top: calc(50% - 24px);
            left: calc(50% - 24px);
            &, &:active {
                outline: none;
                text-decoration: none;
            }
            @include md-and-up {
                top: calc(50% - 36px);
                left: calc(50% - 36px);
            }
            i {
                font-size: 48px;
                @include md-and-up {
                    font-size: 72px;
                }
            }
        }
    }

    //
    // search results/archive
    //

    .archive-intro {
        h1 {
            color: $white;
        }
    }

    .post-content {
        padding-top: 30px;
    }

    //
    // Section pages
    //

    .film-info-wrap {
        @include sm-and-up {
            padding-left: 0;
        }
        @include xs {
            margin-top: 10px;
            margin-bottom: 20px;
        }
    }

    .film-meta {
        color: #777;
        margin-bottom: 5px;
    }

    //
    // Films Page
    //
    &.single-films {
        article {
            a {
                color: $brand-primary;
            }
        }

        .showtimes {
            .callout {
                display: block;
                font-size: 18px;
                margin-bottom: 10px;
            }
        }
    }

    .reveal-gradient-overlay {
        z-index: 1;
    }

    .lounge-rotator {

        .slide {
            position: relative;
            // display: block;
            max-width: 740px;
        }

        .slick-arrow {
            color: $violet;
            opacity: 0.8;
            background-color: transparent;
            border: 0;
            padding: 0;
            position: absolute;
            font-size: 24px;
            &:hover {
                opacity: 1;
            }
            @include md-and-up {
                font-size: 36px;
            }
        }

        .slick-next {
            right: 45px;
            @include sm {
                right: 90px;
            }
            @include md-and-up {
                right: 120px;
            }
        }

        .slick-prev {
            left: 45px;
            @include sm {
                left: 90px;
            }
            @include md-and-up {
                left: 120px;
            }
        }

        .reveal-gradient-overlay {
            display: none;
        }

        .video-play {
            position: absolute;
            width: 100%;
            z-index: 90;
            top: 50%;
            transform: translate(0, -50%);
            text-align: center;
            font-size: 100px;
            color: $white;
            opacity: .7;
            font-size: 45px;
            &:hover {
                opacity: 1;
            }
        }
    }

    .lounge-rotator {
        margin-bottom: 30px;
        position: relative;

        .slide {
            margin-right: 10px;
        }

        .item-text {
            text-align: center;
        }

        .slick-arrow {
            top: calc(50% - 36px);
        }

        .img-bg {
            z-index: 1;
        }
    }

    .nyff2018-logo {
        img {
            display: block;
            margin: 30px 0 20px;
        }
    }

    .sidebar-nav {
        top: 0;
        right: 0;
        position: fixed;
        background-color: $black;
        height: 100%;
        padding: 20px 30px;
        margin-right: -380px;
        transition: .25s ease;
        width: 360px;
        z-index: 201;
        overflow-y: auto;

        @include sm-and-up {
            padding-right: 30px;
        }

        .btn-primary {
            background: $brand-primary;
            border: none;
            font-family: $font-headline;
            text-transform: uppercase;

            &:hover {
                background: darken($brand-primary, 10%) !important;
            }
        }


        .algolia-autocomplete{
            display: block;
            max-width: 100%;
            width: 100%;
            margin: 0;
            input[aria-expanded=true]  {
                @include xs {
                    top: 18px;
                }
            }
        }

        pre {
            @include xs {
                position: static !important;
                margin-top: -60px;
            }
        }

        &.active {
            margin-right: 0;
            @include xs {
                width: 100%;
            }
        }

        .navigation-close {
            display: block;
            text-align: right;
            font-size: 32px;
            color: $white;
        }

        .btn {
            display: block;
        }

        ul {
            border-bottom: 1px solid rgba(255,255,255,.3);
            margin: 15px 0 25px 0;
            padding: 0;

            li {
                display: block;
                clear: both;
                width: 100%;
                &.top-level {
                    margin-bottom: 20px;
                }
            }

            a {
                color: $white;
                font-size: 20px;
                line-height: 28px;
                font-weight: 500;
                &:hover, &:focus, &:active {
                    text-decoration: none;
                }
            }
            &.dropdown-menu {
                li a {
                    font-size: 16px;
                    line-height: 22px;
                }
            }
        }

        ul:last-child {
            border: 0;
        }

        // in sidebar menu, submenus take up full width
        .dropdown-menu {
            border: 0;
            border-radius: 0;
            box-shadow: none;
            background-color: $black;
            text-align: left;
            position: static;
            width: 100%;
            border: 0;
            box-shadow: none;
            padding: 0;
            margin: 0;
            transition: max-height 0.3s ease;
            max-height: 0;
            display: block;
            overflow: hidden;
            opacity: 0;

            a {
                padding: 6px 12px;
                color: $white;

                &:hover {
                    color: $black;
                }
            }
        }

        .dropdown.open .dropdown-menu {
            max-height: 360px;
            opacity: 1;
        }

        .dropdown-backdrop {
            position: static;
        }


        // '+' for dropdown
        a.plus-expand {
            margin: 0;
            padding: 0;
            float: right;
            width: auto;
            opacity: 0.9;
            &:hover {
                opacity: 1;
            }

            &[href]{
                visibility: visible;
                i {
                    display: inline;
                }
            }

            i {
                font-size: 12px;
            }
        }

        .nyff-logo {
            margin: 15px auto;
        }
    }

    &.section-nyff-guide {
        .header {
            h2 {
                font-weight: 700;
            }
        }
        .section-films {

            .film {
                margin-bottom: 30px;

                h3 {
                    margin-bottom: 10px;
                }
            }
            .film-img {
                @include xs {
                    max-width: 160px;
                    margin-bottom: 15px;
                }
                @include xs-wide {
                    max-width: 240px;
                }
            }
        }
    }


}

.nyff-schedule-2020 {
    .schedule-header {
        margin: 20px 0 5px;
        &, p {
            color: $white;
        }
    }

    .fc-resource-area .fc-cell-text {
        @include sm-and-up {
            color: $white;
        }
    }

    .schedule div.day-picker__cont {
        // @include lg {
        //     overflow: visible;
        // }
        // @media screen and (min-width: 1400px){
        //     overflow: hidden;
        // }

    }
    .day-picker__cont div.day-picker__pager {
        @include md-and-up {
            opacity: 0.65;
            cursor: pointer;
            display: block;
            color: $white;
            position: absolute;
            right: 0px;
            top: 25px;
            font-size: 25px;
            z-index: 10;
        }
        @include lg {
            right: -5px;
        }

        &.day-picker__pager--prev {
            left: 0px;
            max-width: 30px;
        }

        &.day-picker__pager--next {
            right: 0px;

        }

        &:hover {
            opacity: 1;
        }
    }
}
