// As much as possible, set type by customizing the default bootstrap variables in _variables.scss

html {
    -webkit-font-smoothing: antialiased;
}



body {
    color: $gray-text-dark;
    font-family: $font-sans;
    font-weight: $font-regular;
}

//The two main fonts
%font-sans {
    font-family: $font-sans;
}

%font-headline {
    font-family: $font-headline;
    text-transform: uppercase;
    letter-spacing: 0.1em;
}

.lead {
    color: $gray-text-medium;
    font-size: 16px;
    font-weight: 700;
}

//a link that appears in a stack with others
.stack-link {
    font-size: 22px;
    line-height: 1.2;

    @include sm-and-up {
        font-size: 18px;

        .secondary & {
            font-size: 16px;
        }
    }
}

.by-line {
    font-size: 16px;
    line-height: 1.3;

    @include sm-and-up {
        font-size: 14px;
    }
}


//common type styles found

%prominent-small-type {
    font-size: 9px;
    line-height: 33px;

    @include sm-and-up {
        font-size: 13px;
    }
}

%prominent-medium-type {
    font-size: 14px;
    line-height: 17px;

    @include sm-and-up {
        font-size: 14px;
        line-height: 18px;
    }
}


//typical interior header tag
h1 {
    color: $black;
    font-weight: $font-regular;

    font-size: 29px;
    line-height: 1.3;

    @include sm-and-up {
        font-size: 38px;
        line-height: 1.2;
    }
}

h2 {
    margin: 10px 0 20px;
}


//defaults for wysiwyg content.

p {
    @include sm-and-up {
        font-size: 18px;
        line-height: 1.4;
    }
}

p,
.entry-content-asset,
.snippet-inline {
    margin-bottom: 20px;
}

a {
    @include sm-and-up {
        color: $violet;
        &:hover {
            color: $violet;
        }
    }
}

// Body type styles for posts
.post-content {

    ul, ol {
        margin: 17px 30px 30px 30px;
        font-size: 16px;
        line-height: 22px;
        ul, ol {
            margin: 15px;
        }
        li {
            margin: 5px 0;
        }
    }
    ul {
        li {
            list-style-type: disc;
        }
    }

    table {
        background: $gray;
        padding: 30px;
        width: 100%;
        margin: 30px 0;
        th {
            padding: 15px;
            border-bottom: 1px solid rgba(0,0,0,0.1);
            margin-bottom: 15px;
        }
        td {
            padding: 5px 15px;
        }
    }
     h4 {
        font-weight: bold;
     }
     h5 {
        font-weight: bold;
     }
}
