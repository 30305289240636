//
// Make containers more responsive -- no arbitrary jumps between sm and lg
// http://stackoverflow.com/a/19438900
//

@media #{screen} and (max-width: #{$screen-lg-min}) {
    .container {
        width: 100%;
        max-width: $container-lg;
    }
}

//
// get rid of outlines on focus
// http://stackoverflow.com/a/24338719
//
textarea:focus,
input[type="text"]:focus,
input[type="password"]:focus,
input[type="datetime"]:focus,
input[type="datetime-local"]:focus,
input[type="date"]:focus,
input[type="month"]:focus,
input[type="time"]:focus,
input[type="week"]:focus,
input[type="number"]:focus,
input[type="email"]:focus,
input[type="url"]:focus,
input[type="search"]:focus,
input[type="tel"]:focus,
input[type="color"]:focus,
.input-group-btn:focus,
.btn:focus,
button:focus,
.uneditable-input:focus,
:focus {
    border-color: none;
    box-shadow: none;
    outline: 0 none;
    outline-color: transparent;
    outline-style: none;
}

select:focus {
    outline-color: transparent;
}

//
// Auto clearing columns
// http://stackoverflow.com/questions/19595706/twitter-bootstrap-3-same-height-fluid-grid-layout
// http://www.bluthemes.com/blog/3/clearing-bootstrap-3-columns
//

// X Small

// Column clear fix
.col-xs-1:nth-child(12n+1),
.col-xs-2:nth-child(6n+1),
.col-xs-3:nth-child(4n+1),
.col-xs-4:nth-child(3n+1),
.col-xs-6:nth-child(2n+1) {
    clear: left;
}

// Tablet
@media (min-width: #{$screen-sm-min}) {
    //  Column clear fix
    .col-sm-1,
    .col-sm-2,
    .col-sm-3,
    .col-sm-4,
    .col-sm-6 {
        clear: none !important;
    }

    .col-sm-1:nth-child(12n+1),
    .col-sm-2:nth-child(6n+1),
    .col-sm-3:nth-child(4n+1),
    .col-sm-4:nth-child(3n+1),
    .col-sm-6:nth-child(2n+1) {
        clear: left;
    }
}


// Medium Desktop
@media (min-width: #{$screen-md-min}) {
    .col-md-1,
    .col-md-2,
    .col-md-3,
    .col-md-4,
    .col-md-6 {
        clear: none !important;
    }

    .col-md-1:nth-child(12n+1),
    .col-md-2:nth-child(6n+1),
    .col-md-3:nth-child(4n+1),
    .col-md-4:nth-child(3n+1),
    .col-md-6:nth-child(2n+1) {
        clear: left;
    }
}


// Large Desktop
@media (min-width: #{$screen-lg-min}) {
    // Column clear fix
    .col-lg-1,
    .col-lg-2,
    .col-lg-3,
    .col-lg-4,
    .col-lg-6 {
        clear: none !important;
    }

    .col-lg-1:nth-child(12n+1),
    .col-lg-2:nth-child(6n+1),
    .col-lg-3:nth-child(4n+1),
    .col-lg-4:nth-child(3n+1),
    .col-lg-6:nth-child(2n+1) {
        clear: left;
    }
}

//responsive text utilities
.text-center-xs {
    @include xs {
        text-align: center;
    }
}

.text-center-sm {
    @include sm {
        text-align: center;
    }
}
