$ndnf-dark: #212122;
$ndnf-green: #8ccea8;
$ndnf-yellow: #ffee2a;
$ndnf-peach: #ff9d8c;

$ndnf-primary: $ndnf-green;
$ndnf-header: $ndnf-dark;
$ndnf-header-hover: $ndnf-primary;

.page-template-template-ndnf-splash.ndnf2019 {
    background-image: linear-gradient(to bottom, $ndnf-primary, $ndnf-yellow, $ndnf-peach);
    @extend .bg-img;
    height: 100%;
}

//both films + festivals
.single-festivals.new-directors-new-films-2019, .single-films.section-ndnf2019 {

    //related posts are hidden
    .recommend-title, .related-posts {
        display: none;
    }

    .content-box a {
        color: $ndnf-primary;
    }

    .footer-sponsor {
        margin-bottom: 5px;
        .sponsor-text {
            font-weight: bold;
            position: relative;
            margin-right: 8px;
            font-size: 10px;
            @include sm-and-up {
                font-size: 12px;
            }
        }

        .sponsor-logo {
            margin-bottom: 5px;
            max-width: 135px;
            @include sm-and-up {
                max-width: 180px;
            }
        }
    }

}

//styles for the New Directors New Films Festival
.single-festivals.new-directors-new-films-2019 {

    //the date/title are in the header so hide from the body
    .single-intro {
        display: none;
    }

    //play button
    a.video-lightbox {
        width: auto;
        left: calc(50% - 36px);
        @include sm-and-up {
            left: calc(50% - 20px);
        }
        font-size: initial;

        background: $black;
        opacity: 0.5;
        padding: 16px 24px;
        border-radius: 50%;
        i {
            font-size: 36px;
            position: relative;
            left: 3px;
        }
    }
}

.single-films.section-ndnf2019 {

    .event-date {

        @include sm-and-up {
            background-image: none;
            time {
                top: 0;
            }
        }
    }
}

.header-ndnf2019 {

    background-color: $ndnf-header;
    position: relative;
    border: 0;

    &::after {
        content: '';
        display: block;
        width: 100%;
        height: 9px;
        border: 0;
        outline: none;
        background-image: linear-gradient(to right, $ndnf-primary, $ndnf-yellow, $ndnf-peach);

    }

    &, a {
        color: $white;
    }

    a {
        &:hover {
            color: $ndnf-header-hover;
        }
    }

    .header-social-nav {
        z-index: 1;
        @include xs {
            margin-top: 15px;
        }
        @include sm {
            float: left;
            margin-bottom: -30px;
        }
        @include md-and-up {
            float: right;
        }

        .social-links {
            position: static;
            margin: auto;
            display: table;
            a {
                color: $white;
                font-weight: 700;
                letter-spacing: 0.1rem;
                &:hover {
                    &, i {
                        color: $ndnf-header-hover;
                    }
                }
            }
        }

        //hide some of the items
        .signup-link, .cart, .login, .app-link, .yt-link {
            display: none;
        }

        //align social links group with left and right edges
        .fb-link {
            padding-left: 0;
        }
        .yt-link {
            padding-right: 0;
        }

        i {
            color:  $white;
        }
    }

    .navbar {
        height: auto; //no height imposed
        @include xs {
            padding: 0;
        }
    }

    .navbar-logo-wrap {
        padding: 25px 0;
        width: 100%;

        @include sm {
            max-width: 400px;
            margin: 0 auto;
            padding: 20px 0 0;
            text-align: center;
        }
        @include md-and-up {
            width: 48%;
            float: left;
        }
        @include lg {
            width: 38%;
        }
    }
    .navbar-nav-wrap {

        @include sm {
            width: 100%;
        }

        @include md-and-up {
            width: 52%;
            float: right;
        }
        @include lg {
            width: 62%;
        }
    }

    .navbar-brand {
        img {
            position: relative;
            max-width: 180px;
            bottom: 10px;
            @include sm {
                bottom: 0;
                max-width: 330px;
            }
            @include md {
                bottom: -10px;
                max-width: 360px;
            }
            @include lg {
                bottom: -10px;
                max-width: 460px;
            }
        }
    }

    .navbar-logos {
        @include xs {
            width: 80%;
            float: left;
        }
        @include xs-wide {
            width: 75%;
            float: left;
        }
        a {
            display: inline-block;
            margin-right: 2%;
            @include sm-and-up {
                margin-right: 3%;
            }
            img {
                width: 100%;
            }
        }
        .ndnf-logo {
            width: 62%;
            max-width: 293px;
            @include xs-wide-and-up {
                width: 60%;
            }

        }
        .filmlinc-logo {
            width: 12%;
            max-width: 60px;
            vertical-align: bottom;
        }
        .moma-logo {
            width: 16%;
            max-width: 87px;
            vertical-align: bottom;
            margin-bottom: 10px;
            @include xs-wide-and-up {
                width: 17%;
            }
            @include sm-and-up {
                margin-bottom: 17px;
            }
        }
    }

    .navbar-header {
        width: 100%;
        // overwrite inherited width from _navbar.scss
    }

    .navbar-toggle, .icon-bar {
        border-color: $white;

    }

    .navbar-toggle {
        margin-right: 0;
    }

    .icon-bar {
        background-color: $white;
    }


    .date-row {
        @include lg {
            margin-bottom: 10px;
        }
    }

    time, .bar {
        min-height: 20px;
        @include md-and-up {
            min-height: 40px;
        }
    }

    .bar {
        // border-bottom: 3px solid $white;
    }

    .event-date {
        text-align: center;
        @include sm-and-up {
            background: url('../img/ndnf-2019-header-rule.png') repeat-x bottom center;
            text-align: right;
        }
    }

    time {
        font-weight: 700;
        font-size: 26px;
        text-align: center;
        position: relative;
        @include sm-and-up {
            background: $ndnf-header;
            text-align: right;
            padding-left: 23px;
        }
        width: 100%;

        @include sm {
            // font-size: 13px;
            position: relative;
            top: 9px;
        }
        @include md-and-up {
            // font-size: 16px;
            // top: 20px;
            font-size: 26px;
            // baseline position to bottom of line.
            position: relative;
            top: 11px;
        }
        @include lg {
            font-size: 26px;
        }
    }

    //differences from default series nav look
    .series-nav {
        border: 0;
        margin-top: 2px;
        li {

            @include sm-and-up {
                &:first-child a {
                    padding-left: 0;
                    text-align: left;
                }
                &:last-child a {
                    padding-right: 0;
                    text-align: right;
                }
            }

            //no hover state changes for now
            &.active>a, >a:hover, >a:focus {
                background-color: $ndnf-header !important;
                color: $white !important;
                border-radius: 0;
            }

            >a:hover {
                color: $ndnf-header-hover !important;
            }
        }

        a[aria-controls] {
            text-transform: uppercase;
            font-family: $font-headline;
            font-size: 28px;
            font-weight: 700;
            border-bottom: 0;
            letter-spacing: 0.15em;
            @include sm {
                font-size: 22px;
                padding-top: 15px;
            }
            @include md {
                font-size: 20px;
                padding-top: 25px;
                line-height: 100%;
            }
            @include lg {
                // padding-top: 25px;
                font-size: 26px;
            }

        }

    }

    .navbar-collapse {
        overflow: hidden;
    }

} //.header-newdirectors2019

.footer-ndnf2019 {

    background-color: $ndnf-primary;
    color: $white;

}
