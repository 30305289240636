//pages where hero image is the background and main container is elevated


.mix-with-hero {

}


.background-hero-page {
    .main-header {
        margin-bottom: 0;
    }
    .content-box {
        @include sm-and-up {
            margin-top: -160px;
        }
    }
    .sidebar {
        @include sm-and-up {
            margin-top: 30px;
        }
    }

}

.nav-tertiary {
    padding: 30px 30px 0 30px;
    border-bottom: 1px solid rgba(0, 0, 0, .1);

    @include sm-and-up {
        padding-top: 75px;
        padding-left: $section-spacing;
        border: 0;
        // border-left: 1px solid rgba(0, 0, 0, .1);
    }

    .section {
        color: $gray-text-dark;
        font-size: 14px;
        letter-spacing: 0.1em;
    }
    &.horizontal {
        padding: 15px 1% 0;
        border-bottom: 1px solid rgba(0, 0, 0, .1);

        @include sm-and-up {
            margin-bottom: 30px;
            li {
                display: inline-block;
                // float: left;
                text-align: center;
                padding: 0 1%;

                // Actual tabs (as links)
                > a {
                    margin-right: 2px;
                    border: 1px solid transparent;
                    font-size: 16px;
                    line-height: 20px;

                }
            }
        }
        ul {
            text-align: center;
        }
    }
    .child-page {
        line-height: 20px;
        margin-bottom: 12px;
        .active {
            color: $gray-text-dark;
        }
    }
    .heading {
        line-height: 1em;
        margin-bottom: 15px;
    }
}

.secondary-nav-col {
    +div {
        @include sm-and-up {
            border-left: 1px solid rgba(0, 0, 0, .1);
            padding-left: 40px;
        }
    }
}
.page-secondary-nav {
    @include sm-and-up {
        .ad {
            margin-top: 180px;
        }
    }
}

.page-template-template-secondary-navigation {
    .page-container>div {
        @include md {
            &:first-child {
                padding-right: 5px;
            }
            &:last-child {
                padding-left: 5px;
            }
        }

    }

    @include md {
        .page.with-nav {
            padding-right: 10px;
        }
        .sidebar {
            padding-left: 10px;
        }
    }
    @include sm-and-down {
        .sidebar {
            margin-top: 30px;
        }
    }
}

.nav-tertiary-divider {
    margin-left: 20px;
}

.grandchild-pages {
    margin: 10px 15px;
    font-weight: normal;
    li {
        margin-bottom: 6px;
    }
    a {
        // color: $violet;
    }
}


.press-releases {
    p {
        font-size: 14px;
        line-height: 18px;
    }
}
