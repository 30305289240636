.schedule__info {
    max-width: 750px;
    margin: 0 auto;
    text-align: center;
    p {
        color: #fff;
    }
}

.widgets {
    position: relative;
    > div {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: auto;
        z-index: $zindex-widgetActive;
        opacity: 1;
        transition: -webkit-transform 0.5s, transition 0.5s, opacity 0.5s;
        &.is-hidden {
            @include md-and-up {
                opacity: 0;
                -webkit-transform: translateX( 100% );
                transform: translateX( 100% );
                z-index: $zindex-widgetInactive;
            }
        }
    }
    @include md-and-up {
        overflow: hidden;
    }
    @include sm-and-down {
        > div {
            position: relative;
        }
        z-index: 1;
    }
}

.schedule {
    // overflow: hidden;
    position: relative;
    @include sm-and-down {
        padding-top: 70px;
    }
}

.button-compressed {
    -webkit-font-smoothing: antialiased;
    background: #748795;
    color: #fff;
    font-family: $font-proxima-condensed;
    padding: 2px 5px;
    text-transform: uppercase;
    padding-top: 65px;
    &:hover {
        color: #fff;
    }
}
