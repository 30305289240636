// layout
$section-spacing-long: 60px;
$section-spacing: 30px; //more module spacing in mobile
$section-spacing-mobile: 15px;

$section-padding: 30px; //when spacing doesn't vary between mobile and desktop

// type
//
$font-sans: "urw-din", sans-serif; // Weights: Light (300), Regular (400), Semibold/Demibold (600), Bold (700)
$font-headline: "urw-din-condensed", sans-serif; // Weights: Regular (400), Bold (700)

//font-weight (built into typekit)
$font-light:         300;
$font-regular:       400;
$font-semibold:      600;
$font-bold:          700;
$font-black:         900;


// colors
$white:     #ffffff;
$white-off: #f3f3f3;
$off-white: $white-off;
$black:     #000000;
$orange:    #ff6633;
$tan:       #e9c9bc;
$blue:      #0b89e1;
$violet:    #7a48ff; //rebrand color

//used sometimes
$green:     #00bf6f;

//grays
$gray-dark:             #1e1e1e;
$gray-medium:           #bbbbbb;
$gray:                  #e8e8e8;

$gray-text-dark:        #454545;
$gray-text-medium:      #787878;
$gray-text-light:       #cdcdcd;

//
// navbar
//

// set navbar breakpoint up to md
// $grid-float-breakpoint:                     $screen-sm-min;

$navbar-default-bg:                         $white;
$navbar-default-border:                     none;
$navbar-border-radius:                      0;
$nav-tabs-border-color:                     transparent;

// transitions

$transition-link:          .1s ease;
$transition-link-hover:    .2s ease;

$transition-button:          .1s ease;
$transition-button-hover:    .2s ease;

//
// bootstrap vars -- customize bootstrap here, leave vendor files untouched
//

$font-family-sans-serif:    $font-sans;
$headings-font-family:      $font-sans;
$btn-border-radius-base:    0;
$btn-border-radius-large:   0;
$btn-border-radius-small:   0;
$input-border-radius:       0;
$input-border-radius-large: 0;
$input-border-radius-small: 0;

$brand-primary:             $violet;
$text-color:                $black;
$link-color:                $violet;

$body-bg:                   $gray;

$grid-gutter-width:         40px;
$container-large-desktop:   1360px + $grid-gutter-width;
$screen-lg:                 1400px;

// slick
