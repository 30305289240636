@import "_variables";

@import "_mixins";

.section-nyff2022.schedule {
    @import "_fullcalendar-defaults";
    a,
    a:hover {
        text-decoration: none;
    }
    @import "_shared";
    @import "_tooltip";
    @import "_modal";
    @import "_list";
    @import "_schedule-actions";
    @import "_calendar";
    @import "_day-picker";
}

.body-filter-active {
    .fc-timeline-event {
        opacity: 0.15;
    }
    .list-row {
        opacity: 0.05;
    }
}
