// Misc Single post (firm/series/festival) related styles

//
// Default
//

.page-container {
    min-height: 40vh; // a little space on super short pages (like 404)
    .container {
        @include xs {
            // padding: 0;
        }
    }
}

.post-content {
    @include sm {
        padding: 0 30px 30px;
    }

    @include md {
        padding: 0 60px 60px;
    }
    @include lg {
        padding: 0 120px 120px;
    }
    p {
        @include xs {
            font-size: 16px;
            line-height: 22px;
        }
    }
}

.middle-content .post-content, .col-md-6 .post-content, .col-md-8 .post-content, .post-about .post-content { // no side padding when post content is in columns
    @include sm {
        padding: 0 0 30px;
    }
    @include md {
        padding: 0 0 60px;
    }
    @include lg {
        padding: 0 0 120px;
    }
}

.single-intro {
    .inner {
        background: $white;
        padding: $section-padding $section-padding 0 $section-padding ;
    }

    .post-type {
        // color: $gray-text-medium;
        font-weight: $font-bold;

        @extend %prominent-small-type;
    }

    .included-in {
        @extend %prominent-small-type;
        font-weight: $font-bold;

        @include xs {
            display: block;
            line-height: 1em;
        }
    }

    h1 {
        font-size: 30px;
        line-height: 36px;
        margin-bottom: 0px;
        @include sm-and-up {
            font-size: 38px;
            line-height: 43px;
        }

    }
    h1+p {
        // margin-bottom: 20px;
    }
    .title-wrap {
        padding-bottom: 20px;
    }

}

.single-post, .single-announcements, .page-template, .blog {
    .single-intro {
        padding-bottom: 30px;
        border-bottom: 1px solid $gray;
    }
    .post-content {
        padding: 0 30px 30px;
        @include md {
            padding: 0 60px 60px;
        }
        @include lg {
            padding: 0 120px 120px;
        }
    }
    .imported {
        img, .wp-caption {
            width: 100% !important;
            height: auto !important;
        }
    }
    .wp-caption {
        width: 100%;
    }
}
.single-post {
    .daily-logo {
        max-width: 100px;
        margin: 0;
    }
    .post-content {
        img {
            width: 100%;
        }

    }
}

.series-nav {
    li a {
        font-size: 16px;
        padding: 10px 20px;
        font-weight: bold;
        &:hover {
            background: #fbfbfb;
            text-decoration: none;
        }
    }
    .active {
        a {
            border: 0;
        }

    }
}

.post-title-meta {
    font-weight: bold;
    letter-spacing: 0.1rem;
    color: $gray-text-medium;
    &, a {
        font-size: 16px;
    }
    @include xs {
        line-height: 18px;
    }
}

.post-shares {
    >span { // "Share"
        font-weight: $font-semibold;
        font-size: 7px;
        margin-right: 10px;
        line-height: 16px;
    }

    >div {
        display: inline-block;
        float: right;
    }

    a {
        margin-right: 10px;
        line-height: 33px;

        &:last-child {
            margin-right: 0;
        }
    }

    i {
        color: $gray-text-dark;

    }

    &.hidden-xs {
        position: absolute;
        bottom: 0;
        right: 15px;
    }
}

.post-meta {
    font-style: italic;
    color: $gray-text-medium;
    // margin-bottom: 30px;
}
.post-content {
    color: $black;
    // margin-bottom: 20px;
    padding-bottom: 60px;
    margin-top: 30px;
}
.post-feature, .media-rotator {
    img {
        width: 100%;
    }
}

.promo-callout {
    background: #fafafa;
    font-family: $font-headline;
    color: $violet;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    font-size: 20px;
    font-weight: $font-bold;
    text-align: center;
    padding: 30px 15px;
    @include sm-and-up {
        margin-left: -30px;
    }
}

a.post-type {
    &:hover {
        text-decoration: none;
        color: $link-hover-color;
    }
}

.offerings { //Showtimes / Admissions

    padding-top: $section-padding;
    padding-bottom: $section-padding;
    border-bottom: 1px solid #f5f5f5;
    @include sm-and-up {
        padding: $section-padding 10px $section-padding 0;
        border-right: 1px solid #f5f5f5;
        border-bottom: 0;
    }
    h3 {
        color: $gray-text-dark;
        font-size: 17px;
        font-weight: $font-bold;
        line-height: 10px;

        @include sm-and-up {
            font-size: 18px;

        }
    }

    .item {
        @extend %prominent-medium-type;
    }

    h4 {
        @extend %prominent-medium-type;
        font-weight: $font-bold;

    }

    .arrow {
        font-size: 15px;
        font-weight: $font-bold;
        line-height: 25px;

        @include sm-and-up {
            font-size: 14px;
            line-height: 22px;
        }
    }

    .promotion {

        font-style: italic;
        font-size: 14px;
        line-height: 17px;


        @include sm-and-up {
            font-size: 14px;
            line-height: 15px;
        }

        +a {
            font-size: 14px;
            font-weight: $font-bold;
            line-height: 17px;

            @include sm-and-up {
                font-size: 14px;
                line-height: 18px;
            }
        }
    }
}


body.single-films, body.single-events {
    .showtimes {
        margin: 25px 0;
    }

    .day-showtimes {
        margin: 0 0 20px;
    }

    .sponsors {
        img {
            margin-right: 20px;
        }
    }

    .middle-content {
        padding-top: 30px;
    }

    .show-time {
        max-width: 320px;
    }
    .showtime {
        line-height: 1em;
        margin-bottom: 10px;
    }
    .where-when {
        float: left;
        min-width: 70px
    }

    .ticket-link {
        float: left;
        margin-left: 10px;
        &:not([href]){ // gray out past links
            color: $gray-medium;
            &:hover {
                text-decoration: none;
            }
        }


    }

    .item {
        @include vertical-margin($section-padding / 2);
    }

    .middle-content {
        a {
            font-weight: $font-bold;
        }
    }


    .venue {
        margin-bottom: 20px;
        h4 {
            margin: 0;
        }
        a {
            font-weight: $font-regular;
        }
    }

    .cta {
        display: block;
        margin-bottom: 20px;
    }
    h1 {
        margin-bottom: 10px;
    }
    .directors, .film-header-details {
        font-size: 16px;
        line-height: 20px;
    }
    .film-header-details {
        margin: 5px 0 30px;
        color: $gray-text-dark;
        text-align: center;
        border-bottom: 1px solid #f5f5f5;
        padding-bottom: 15px;
    }
}

.separate-bullet {
    color: $gray-text-light;
    padding: 0 5px;
}

.film-meta {
    ul {
        margin: 0;
        padding: 0;
    }
    li {
        display: inline;
        &:after {
            content: "\2022";
            color: $gray-text-light;
            padding: 0 5px;
        }
        &:last-child {
            &:after {
                content: "";
            }
        }
    }
}

.offerings-admissions {
    .price {
        font-weight: $font-bold;
        display: inline-block;
        min-width: 72px;

        @include sm-and-up {
            min-width: 36px;
        }
        // background-color: salmon;
    }
}

.post-about {
    margin-bottom: 20px;
    background-color: $white;
    >.inner {

        padding: 0 $section-padding;
        >.row {
            padding-bottom: $section-padding;
            &:last-child {
                padding-bottom: 0;
            }
        }
    }

    .ads {
        padding: $section-padding;
        @include xs {
            text-align: center;
            padding: 0;
        }

    }
    // .post-content {
    //     padding-left: 0;
    //     padding-right: 0;
    // }

}

article.post {
    // padding: $section-padding;
}

.background-hero-page {

}
.background-hero {
    height: 600px;
    background-repeat: no-repeat;
    background-size: cover;
    // background-attachment: fixed;
    @include xs {
        height: 200px;
    }
}

.page-template {
    .tertiary-nav-col {

    }
    .post-content {
        margin-top: 0;
        padding-top: 60px;
        padding-bottom: 60px;
        &.with-nav {
            padding-top: 0;
        }
        @include xs {
        padding-top: 30px;
        padding-bottom: 30px;
        }
    }
}


.single article.post {
    margin-bottom: 60px;
}

.recommend-title {
    margin-bottom: 30px;
}

// for password protected pages/singles
form.password-form {
    position: relative;
    padding: 30px;

    .password-box {
        max-width: 200px;
        margin: 5px 0 10px;
        color: $text-color;
    }
}


// one off styles (can be removed when no longer applicable)
.showtime {
    &[data-event-id="58121"], &[data-event-id="58026"]{
        display: none;
    }
}
