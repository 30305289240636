.list-row {
    background: #fff;
    border-left: 5px solid;
    border-top: 1px solid #d6d6d6;
    padding: 18px 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .body-filter-active & {
        display: none !important;
    }

    &.has-filter-active {
        display: flex !important;
        opacity: 1 !important;
    }

    p {
        margin: 0;
    }

    &__time {
        font-weight: bold;
        width: 15%;
        letter-spacing: 0.1em;
        > * {
            display: block;
        }
    }
    &__title {
        font-size: 15px;
        font-weight: bold;
        width: 45%;
        a {
            color: $text-color-dark;
        }
        small {
            font-size: 11px;
            line-height: 11px;
            font-weight: 600;
            text-transform: uppercase;
        }
    }

    &__location {
        font-size: 15px;
        font-weight: bold;
        width: 35%;
        small {
            font-size: 11px;
            color: lighten($text-color-dark, 25%);
            letter-spacing: 0.07em;
            font-weight: 600;
            text-transform: uppercase;
        }
    }

    &__duration {
        width: 15%;
    }

    &__actions {
        text-align: center;
        width: 15%;
        &__tooltip {
            background: #efefef;
            display: inline-block;
            margin-bottom: 5px;
            padding: 2px 5px;
            font-size: 10px;
            text-transform: uppercase;
        }
        a,
        a:hover {
            color: $text-color-dark;
            display: block;
            font-weight: bold;
        }
    }

    @include md-and-up {
        .list-row {
            &__title {
                font-size: 18px;
                padding-right: 25px;
            }
            &__time {
                span {
                    display: none;
                }
            }
        }
    }

    @include sm-and-down {
        padding-left: 20px;
        &__time {
            text-align: center;
            margin-right: 35px;
            width: 15%;
        }
        &__title {
            width: 85%;
            p:last-of-type {
                font-size: 12px;
                font-weight: normal;
            }
        }
    }

    // section-specific
    @include section-loop {
        border-left-color: $section-color;
        .list-row__title {
            small {
                font-size: 11px;
                letter-spacing: 0.1em;
                color: $section-color;
            }
        }
    }
    &:last-child {
        border-bottom: 1px solid #d6d6d6;
    }
}
