
//
// Daily Section
//

.daily-logo {
    margin: 30px 0 20px;
    color: $gray-dark;
    font-size: 26px;
    line-height: .9;
    font-weight: $font-light;
    font-family: $font-headline;
    text-align: center;

    a {
        color: inherit;
        margin-right: 10px;
        position: relative;
        top: 2px;


    }

    @include sm-and-up {
        text-align: left;

        .cta {
            display: inline;
            font-size: 16px;
        }
    }

    img {
        max-width: 215px;
    }

    strong {
        font-weight: $font-bold;
    }

    .cta {
        margin-top: 5px;
        display: block;
        font-size: 18px;
    }
    em {
        font-size: 14px;
        font-style: italic;
        color: $gray-text-medium;
        text-transform: none;
        text-align: center;
        margin-top: 10px;
        display: block;

        @include md-and-up {
            display: inline;
            margin-left: 15px;
            margin-top: 0;
            text-align: left;
            position: relative;
            top: 2px;
        }

        @include sm {
            text-align: left;
        }
    }
}

.home-purchase-button {
    font-weight: 700;
    font-size: 24px;
}

.home-daily-section {
    background-color: $white;
    padding: 15px;
    margin-bottom: 30px;

    @include md-and-up {
        .featured {
            margin-bottom: 0;
        }
    }
    .snippet-blog {
        &:last-child {
            margin-bottom: 0;
        }
        .blog-text {
            .stack-link {
                @include md-and-down {
                    font-size: 16px;
                    line-height: 20px;
                }
                @include lg {
                    font-size: 18px;
                    line-height: 22px;
                }
            }
        }
    }

}

//photo galleries section in homepage
.home-galleries {

    background-color: $white;
    padding: 0 15px;

    hr {
        color: $gray;
        padding-top: 1px;
        margin: 0;
    }

    h2 {
        font-size: 18px;
        a {
            &:hover {
                text-decoration: none;
            }
        }
        span {
            color: $black;

        }
    }

    .secondary {
        position: relative;

        @include md-and-up {
            &>div:last-child {
                position: absolute;
                bottom: 0;
            }
        }
    }

}

//
// Film Comment Section
//

.comment-logo-header {
    text-align: center;
    margin: 20px 0 20px;
    @include sm {
        text-align: left;
    }
    img { //svg logo
        width: 100%;
        max-width: 250px;

    }

    em {
        font-size: 14px;
        font-style: italic;
        color: $gray-text-medium;
        text-transform: none;
        margin-top: 10px;
        display: block;
        @include sm {
            text-align: left;
        }
    }

    @include md-and-up {
        margin-top: 30px;
        text-align: left;

        img {
            margin-right: 20px;
            max-width: 265px;
            margin-top: 7px;
        }

        em {
            display: inline;
            position: relative;
            top: 10px;
        }
    }
}

.home-film-comment-section {
    background-color: $white;
    padding: 15px;

    .inner {

    }

    a {
        color: $gray-text-dark;
    }

    .featured {
        img {
            margin-bottom: 10px;
        }
        .text {

            h2 {
                font-size: 22px;
                line-height: 26px;
                margin-bottom: 4px;
                margin-top: 0;
                @include sm-and-down {
                    font-size: 18px;
                    line-height: 22px;
                }
            }

            .by-line {
                margin-bottom: 5px;
            }


            p {
                font-size: 13px;
            }

            // tighten left grid
            @include sm-and-up {
                margin-left: -1 * $grid-gutter-width / 2;
            }
        }
    }

    .secondary {
        padding-top: 20px;

        @include md-and-up {
            padding-top: 0;
        }

        .item {
            margin-bottom: 20px;
        }

        .title {
            font-size: 16px;
            line-height: 20px;
            margin-bottom: 4px;
        }
    }
}

//
// Ads
//

.home-ads {
    background: #ececec;
    text-align: center;
    padding: 15px 0;
    margin: 30px 0;
    @include sm-and-up {
        padding-top: 0;
        margin: 0 0 30px;
    }

    .ad {
        margin: 15px auto;
        @include sm-and-up {
            margin: 0 auto 15px;
        }
    }

    span {
        text-transform: uppercase;
        font-weight: $font-bold;
        letter-spacing: 0.1em;
        font-size: 10px;
        color: $gray-text-medium;
    }

    @include lg {
        margin-bottom: 0;
    }
    @include xs {
        margin: 0 0 30px -10px;
        padding: 0;
        // margin-left: -10px;
        background: none;

    }
}

.announcements-holder  {


}

.section-announcements-short {
    text-align: left;
    background: $white;

    @include md-and-up {
        padding: 30px 30px 20px;
    }

    .title {
        margin-bottom: 15px;
        color: $brand-primary;
        font-size: 20px;
    }

    h3 {
        line-height: 1.2;
        font-weight: $font-bold;
    }

    .item {
        margin-bottom: 20px;
        h3 {
            line-height: 1.3;
            font-size: 19.5px;
            font-weight: 400;
        }
        &, a {
            color: $gray-text-dark;
        }
    }


    .more {
        float: right;
        font-size: 15px;
        color: $brand-primary;
        i {
            position: relative;
            top: 2px;
        }
    }
}


//home page overriding styles
.page-template-template-home {


    .daily-logo {
        nav {
            display: inline-block;
            a {
                color: $black;
                font-family: $font-sans;
                font-size: 16px;
                font-weight: $font-bold;
                position: relative;
                top: 2px;
            }
        }
    }


    .now-playing-holder {
        background-color: #313131;

        .bottom-buttons {
            padding: 0px 20px 20px;


            @include xs-wide-and-up {
                display: flex;
                gap: 15px;
            }
            a {
                margin-bottom: 15px;
                @include xs-wide-and-up {
                    flex: 1;

                }
                @include sm-and-down {
                    font-size: 14px;
                }
            }

        }


    }


}


// one time changes
.np-showtimes {
    li[data-event-id="58121"], li[data-event-id="58026"]{
        display: none;
    }
}




.sb-twitter-slider {
    overflow: hidden;
    margin: 30px 0;
    position: relative;
    left: -4px;
    @include md-and-up {
        margin: 45px 0;
    }


    .ctf-header {
        display: none;
    }

    .ctf-owl-stage-outer {
        min-height: 480px ;
    }

    .ctf-carousel {
        .ctf-owl-item {

        }
    }

    .ctf-item {
        padding: 20px !important;
    }

    #ctf.ctf_palette_custom_2 {
        .ctf-tc-desc, .ctf-tc-heading, .ctf-tweet-text, .ctf-quoted-tweet-text {
            @include md-and-up {
                font-size: 15px !important;
            }
        }

        .ctf-tc-url, a[rel^="nofollow"] {
            &, span {
                color: $brand-primary;
            }
        }
    }
}