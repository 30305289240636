
.gala-hero {
    position: relative;
    padding: $section-spacing-long 0 (2 * $section-spacing-long);

    &, h1 {
        color: $white;
    }

    figure { //profile image of honoree
        margin: $section-spacing auto;
        display: table;
        @include sm-and-up {
            display: initial;
            margin: 0;
        }

    }

    .overlay.dark {
        background-color: $black;
        height: 50%;
        @include sm {
            height: 40%;
        }
        @include md {
            height: 40%;
        }
        @include lg {
            height: 40%;
        }
    }

    .overlay.gray {
        background-color: $gray-dark;
        top: 50%;
        height: 50%;
        @include sm {
            top: 40%;
            height: 60%;
        }
        @include md {
            top: 40%;
            height: 60%;
        }
        @include lg {
            top: 40%;
            height: 60%;
        }
    }

    .display-date {
        font-size: 15px;
        @include md-and-up {
            font-size: 24px;
        }

        display: block;
        font-weight: $font-semibold;
    }

    .venue {
        font-size: 14px;
        @include md-and-up {
            font-size: 20px;
        }
        color: $gray-text-light;
    }

    .honoree {
        margin-bottom: $section-spacing-mobile;
        letter-spacing: 2px;

        h3 {
            font-size: 14px;
            @include md-and-up {
                font-size: 19px;
            }
            @include lg {
                font-size: 24px;
            }
            margin-bottom: 0;
        }

        h1 {
            line-height: 1.4;
            letter-spacing: 1px;
            font-size: 21px;
            @include md-and-up {
                font-size: 48px;
            }
            @include lg {
                font-size: 64px;
            }


        }
    }

    .presenters {
        margin-bottom: $section-spacing-mobile;
        .title {
            letter-spacing: 2px;
            margin-bottom: 5px;
        }

        .dot {
            color: $gray-text-dark;
            font-size: 16px;
            @include md-and-up {
                font-size: 26px;
            }
            margin: 0 $section-spacing-mobile;
        }
    }

    .presenter {
        font-weight: $font-semibold;
        line-height: 1.6;
        font-size: 15px;
        @include md-and-up {
            font-size: 24px;
        }
    }

    .details {
        @include xs {
            text-align: center;

            img {
                margin: auto;
                &+time {
                    margin-top: 10px;
                }
            }
        }
    }
    .details-top { //hero top details
        margin-bottom: 10px;
        line-height: 1.2;
        @include md {
            margin-bottom: $section-spacing;
        }
        @include lg {
            margin-bottom: $section-spacing-long;
        }

        img {
            display: block;
            margin-bottom: $section-spacing-mobile;
        }
    }

    .details-bottom { //hero bottom details

    }

    .share-links {
        text-align: inherit;
    }

    a.cta {
        margin-bottom: $section-spacing;
    }

} // /.gala-hero

.gala-event-details {

    @include xs {
        text-align: center;
    }

    .event {
        margin-bottom: $section-spacing;
    }

    .event-time {
        font-weight: $font-light;
        margin-bottom: 15px;

    }

    time {
        font-size: 42px;
        line-height: 0.6; //tighten spacing with meridican beneath
        display: block;
    }

    .meridian {
        font-size: 22px;
        letter-spacing: 2px;
    }

    .title {
        font-size: 22px;
        font-weight: $font-bold;
    }



}

.gala-title {
    color: #333333;
    font-family: $font-sans;
    font-size: 20px;
    font-weight: $font-light;
    letter-spacing: 1px;
}

.gala-faq {

    padding-top: 30px;

    .qna-pair {
        border-bottom: 1px solid $gray;
    }

    .question {

        &:hover {
            &,  a {
                color: $brand-primary;
            }
        }
        padding: 10px 0;

        a {
            color: $black;
            font-weight: $font-bold;
            font-size: 18px;
            text-decoration: none;
            @include xs {
                color: $brand-primary;
            }
        }
    }

    .answer {
        .panel-body {
            padding: $section-spacing-mobile 0;
            border-top: 1px solid $gray;
        }

    }

    .plus {
        display: inline-block;
        font-size: 20px;
    }

    .panel-group {
        margin-bottom: $section-spacing-mobile;
    }

    .followup-text {
        font-size: 14px;
        line-height: 1.4;
    }

}

.gala-plain-content { //wysywigs used in ngala page
    h2 {
        line-height: 1.4;
        font-size: 15px;
        @include md-and-up {
            font-size: 25px;
        }
    }

    strong {
        @include md-and-up {
            font-size: 22px;
        }
    }


    p {
        line-height: 1.4;
        font-size: 12px;
        @include md-and-up {
            font-size: 16px;
        }

    }

    .wide p {
        font-size: 18px;
        line-height: 1.5;
        @include md-and-up {
            font-size: 25px;
        }
    }

    .blurb {
        margin-bottom: 30px;
    }
} // .gala-plain-content

.page-template-template-gala  {

    a.cta {
        display: block;
        font-size: 23px;
        line-height: 0.8;
    }

    a.arrow {
        i {

        }
    }


    .main-header {
        margin-bottom: 0;
    }

    //content block (hero + content beneath) maxes at 1000px
    .hero-content, .content-box {
        max-width: 1000px;
        margin-left: auto;
        margin-right: auto;
    }

    .gala-title {
        margin-bottom: $section-spacing;
    }

    .full-image {
        &.img-bg {
            img {
                width: 100%;
            }
        }
    }


    .content-box {
        margin-top: -(2 * $section-spacing-long);
        padding-top: $section-spacing-long;
        padding-bottom: $section-spacing-long;

        @include xs {
            padding: 0 15px;
        }
    }

    .section-padded {
        border-bottom: 1px solid $gray;
        &:last-child {
            border-bottom: 0;
        }
    }
} // /.page-template-template-gala
