.alert-bar {
    position: relative;
    background-color: $violet;
    color: $white;
    padding: 5px 0;
    font-family: $font-sans;
    font-weight: $font-semibold;
    z-index: 1;
    font-size: 14px;
    line-height: 1.3;
    margin-bottom: 15px;
    @include sm-and-up {
        padding: 10px 0;
        font-size: 16px;
    }

    .close {
        position: absolute;
        color: $white;
        top: 5px;
        right: 5px;
        text-decoration: none;
        opacity: 0.9;
        &:hover {
            opacity: 1;
        }

        @include sm-and-up {
            top: 4px;
            right: 5%;
            font-size: 18px;
        }

        i {
            font-weight: $font-bold;
            font-size: 13px;
        }

    }
}
