.gala-timeline-header {

}

.wp-caption {
    margin-bottom: 15px;
}

blockquote {
    border: 0;
    background-color: $white-off;
    &, p {
        color: $text-color;
    }
}

.flex-tl-slider {
    overflow: hidden;

    .tl-slider {
        position: relative;
    }

    .caption-text {
        color: $white;
        font-size: 12px;
        max-width: 80%;
        padding-left: 15px;
        @include xs-wide-and-up {
            padding-left: 0;
        }

        // @include xs {
        //     align-self: flex-end;
        //     max-width: 60%;
        // }
    }

    .slide {
        padding: 0 8px;
        @include md-and-up {
            &:hover {
                .caption-holder.desktop {
                    text-decoration: none;
                    opacity: 1;
                }
            }
        }
    }

    .caption-holder.mobile {
        .caption-text {
            margin-top: 10px;
        }
    }


    .caption-holder.desktop {
        @include sm-and-down {
            display: none;
        }

        padding: 15px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        opacity: 0;
        background-color:  transparentize($text-color, 0.33);
        transition: opacity  $transition-link-hover, background-color $transition-link-hover;
    }

    .slide-img-holder {
        display: block;
        position: relative;
    }

    .slick-arrow {
        border: 0;
        outline: none;
        border-radius: 50%;
        background-color: $white;
        color: $black;
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        top: calc(50% - 45px);
        font-size: 12px;
        width: 24px;
        height: 24px;
        @include sm-and-up {
            font-size: 24px;
            width: 48px;
            height: 48px;
        }
        @include md-and-up {
            top: calc(50% - 24px);
        }
    }

    .slick-prev {
        left: 30px;
        z-index: 1;
        i {
            position: relative;
            left: -2px;
        }
    }

    .slick-next {
        right: 30px;
    }
}

.flex-tl-tout-rows {

    .rows-title {
        font-weight: $font-bold;
        font-size: 24px;
        margin-bottom: 30px;
    }

    .image-holder {
        margin-bottom: 8px;
        @include sm-and-up {
            margin-bottom: 10px;
        }
    }
    .tout {
        margin-bottom: 30px;

        @include xs {
            max-width: 360px;
            display: table;
            margin: 0 auto 30px;
        }
    }
}

.flex-tl-blurb {
    h3 {
        font-weight: 600;
        font-size: 2rem;
        margin-bottom: 15px;
    }
    img {
        &.size-large, &.size-full {
            margin-bottom: 10px;
        }
    }
    &, p {
        color: $white;
        line-height: 1.5;
        font-size: 1.5rem;
        @include md-and-up {
            font-size: 18px;
        }
    }

    blockquote p {
        color: $text-color;
    }

}

.tl-collage-hero {
    .inner-main {
        padding: 0 0 45px;
        font-size: 18px;

        @include xs-wide-and-up {
            float: right;
            width: calc(100% - 120px);
        }
        @include sm-and-up {
            width: calc(100% - 270px);
        }

        .title {
            font-weight: $font-bold;
            margin-bottom: 10px;
        }

        .blurb {
            font-size: 14px;
            line-height: 1.4;
            margin-bottom: 30px;
            @include xs-wide-and-up {
                margin-bottom: 0;
            }
            @include md-and-up {
                margin-bottom: 30px;
            }
        }
    }

    .tl-collage-text {
        position: relative;
    }

    .collage {

        position: relative;

    }

    .collage-img-1 {

        top: 0;
        left: 0;
        width: 55%;
        z-index: 10;

        @include xs-wide-and-up {
            position: absolute;
            left: -15px;
            max-width: 120px;
        }
        @include sm-and-up {
            max-width: 270px;
        }

        &.mobile {
            display: block;
            @include xs-wide-and-up {
                display: none;
            }
        }

        &.desktop {
            display: none;
            @include xs-wide-and-up {
                display: block;
            }
        }
    }

    .collage-images-secondary {
        position: relative;
        // border: 1px solid $white;
        min-height: 360px;

        @include xs-wide-and-up {
            max-width: 480px;
        }
        @include sm-and-up {
            float: right;
            width: 480px;
        }
        @include md-and-up {
            width: 100%;
            max-width: initial;
        }



        .collage-img-2 , .collage-img-3, .collage-img-4 {
            position: absolute;
            @include xs-wide-and-up {
                // max-width: 300px;
            }
        }

        .collage-img-2 {
            top: 60px;
            right: 0;
            z-index: 20;
            width: 55%;
            @include xs-wide-and-up {
                z-index: 10;
            }

            @include md-and-up {
                top: 0;
                right: initial;
                max-width: 320px;
            }
        }

        .collage-img-3 {
            top: 120px;
            left: 0;
            z-index: 20;
            width: 55%;

            @include xs-wide-and-up {
                z-index: 5;
                // top: 230px;

            }
            @include md-and-up {
                top: 240px;
                left: initial;
                max-width: 320px;

            }
        }

        .collage-img-4 {
            top: 200px;
            right: 0;
            width: 55%;
            z-index: 20;
            @include xs-wide-and-up {
                z-index: 1;
                top: 0;
            }

        }
    }
}

.gala-timeline-group {
    color: $white;

    // .group-title {
    //     display: table;
    //     margin-right: auto;
    //     margin-left: auto;
    // }
    padding: 0 15px;
    @include sm {
        padding-left: 150px;
    }

    .group-title {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        width: 60px;
        height: 60px;
        border-radius: 50%;
        background-color: $brand-primary;
        color: $white;
        letter-spacing: 0px;
        @include sm-and-up {
            width: 120px;
            height: 120px;
            float: left;
            margin-left: -60px;
            font-size: 32px;
            font-weight: $font-bold;
        }
    }

    .group-header {
        @include sm-and-up {
            margin-bottom: 60px;
        }
        .blurb {
            padding: 30px 0;
            @include sm-and-up {
                padding-left: 90px;
                font-size: 15px;
                max-width: 80%;
            }

        }
    }

    .entry-header {
        position: relative;
        margin-bottom: 30px;
        @include xs-wide {
            margin-bottom: 45px;
        }
        @include sm-and-up {
            min-height: 140px;
        }
    }
    .entry-short-title {
        font-weight: $font-bold;
        background-color: $black;
        z-index: 10;
        font-size: 48px;
        @include xs-wide-and-up {
            position: absolute;
            display: inline-block;
            left: -15px;
        }
        @include sm-and-up {
            font-size: 40px;
            left: -160px;
        }
        @include md-and-up {
            font-size: 80px;
            left: -210px;

        }
    }

    .entry-title {
        position: relative;
        font-size: 20px;
        font-weight: $font-bold;
        margin-top: 0;

        @include xs-wide-and-up {
            display: block;
            margin-left: 120px;
        }
        @include sm-and-up {
            margin-left: 0;
        }
        @include md-and-up {
            top: 25px;
            font-size: 38px;
        }
    }

    .entry {
        // border-left: 3px solid $brand-primary; // TODO , switch to js-border-draw-left effect
        padding: 0 20px 45px;
        position: relative;
        margin-top: -35px;

        @include xs-wide {
            padding: 0 30px 45px;
        }
        @include sm-and-up {
            padding-left: 120px;
            padding-top: 60px;
            margin-top: -65px;
        }
    }

    .flex-tl-slider, .flex-tl-blurb, .flex-tl-tout-rows {
        margin-bottom: 30px;
        @include sm-and-up {
            margin-bottom: 45px;
        }
        @include lg {
            margin-bottom: 60px;
        }
    }
}



.page-template-template-timeline, .page-template-template-timeline-group {
    $gala-off-white:  darken($white, 15%);
    background-color: $black;
    color: $gala-off-white;

    .module.timeline {
        margin-top: 30px;
    }

    .main-header {
        background-color: $black;

        .header-title {
            margin: 0;
        }

        .header-menu>.navbar-nav {
            display: block;
        }
    }

    .timeline-load-more {
        margin: 30px auto;
        &.up {
            .fa-chevron-down {
                display: inline-block;
                transform: rotate(180deg);
            }
        }
    }

    .col-timeline-nav {
        position: relative;
    }

    .timeline-nav-main {
        margin-bottom: 30px;
        position: fixed;

        @include xs {
            text-align: center;
            display: table;
            margin: 0 auto 30px;
            bottom: 0;
            left: 0;
            z-index: 10;
            background: black;
            width: 100%;
            margin: 0;
        }
        @include xs-wide {
            display: table;
            margin-left: 15px;
        }

        @include md {
            position: absolute;
        }
        @include md-and-up {
            margin-left: 0px;
        }
        @media screen and (min-width: 1250px) and (max-width: 1500px)  {
            position: absolute;
        }
        @media screen and (min-width: 1450px)  {
            margin-left: -30px;
        }

        @media screen and (min-width: 1600px)  {
            margin-left: -90px;
        }

        color: $white;
        header {
            @include xs {
                display: none;
            }
            margin-bottom: 15px;
        }

        .ranges {
            display: flex;
            flex-direction: row;


            @include sm-and-up {
                flex-direction: column;
                top: 0;

            }
        }

        a {
            font-weight: 700;
            color: $white;
            padding: 5px;
            border-top: 2px solid transparentize($white, 0.7);
            font-size: 14px;
            @include xs-wide {
                font-size:  20px;
                padding-right: 10px;
            }
            @include sm-and-up {
                border-top: 0;
                border-left: 2px solid transparentize($white, 0.7);
                padding: 5px 10px;
                font-size: 18px;

            }

            &.active {
                border-top: 2px solid $white;
                @include sm-and-up {
                    border-top: 0;
                    border-left: 2px solid $white;
                }
            }
        }
    }

    .timeline-secondary {
        border-left: 3px solid $brand-primary;
        margin-left: 15px;
        @include sm-and-up {
            padding-bottom: 0;
            margin: 0;
        }

        .start {
            display: block;
            background-color: transparent;
            max-width: 80px;
            position: relative;
            top: -100px;
            margin-bottom: 30px;
            left: -40px;
            @include sm-and-up {
                top: -70px;
            }

        }
    }

    .gala-timeline {
        margin-top: -120px;
        @include xs-wide-and-up {
            padding-top: 0;
            margin-top: -200px;
        }
        @include md-and-up {
            margin-top: -260px;
        }
    }

    // Draws a left border
    // Inspired from: https://codepen.io/giana/pen/yYBpVY
    .js-border-draw-left {

        // effect setup
        border: 0;
        transition: color 0.25s;
        position: relative; // Required, since we're setting absolute on pseudo-elements
        &::after {
            box-sizing: inherit;
            content: '';
            position: absolute;
            border: 2px solid transparent;     // strt with invisible border
            width: 0;
            height: 0;
            top: 0;   // And this the bottom & left borders (expands left, then up)
            right: 0;
        }

        // the effect trigger class
        &.border-left-active {
            &::after {
                border-left-color: $brand-primary;
                transition: height 1s ease-in 0.25s; // And finally height
                width: 100%;
                height: 100%;
                z-index: -10;
            }
        }
    }
}
