$text-dark: #454545;

// NYFF Colors
$nyff-1: #6699ff;
$nyff-yellow: #f4f531;
$nyff-blue: #3998d8;
$nyff-pink: #df2ca5;
$nyff-purple: #a61ec7;
$nyff-green: #599684;
$nyff-orange: #b07837;

// overall container
.fc-view-container {
    box-shadow: 0 0 10px 0 transparentize( #000, 0.85 );
}

// Adjust buttons
.fc-toolbar {
    .flexbox & {
        align-items: center;
        display: flex;
        justify-content: space-between;
        margin-bottom: 1em;
        text-align: center;
        .fc-center,
        .fc-clear {
            display: none;
        }
    }
    .fc-right p {
        font-size: 12px;
    }
    .fc-left {
        font-size: 22px;
        font-weight: bold;
         p.page-title {
            font-size: 36px;
            margin: 0;
         }
         h2 {
            font-size: 22px;
            line-height: 55px;
         }
         button {
            line-height: 2.5em;
            padding: 0;
            span {
                margin: 0;
                padding: 0;
            }
            .fc-button-group {
                margin-left: 0 !important;
            }
            .fc-icon-left-single-arrow:after,
            .fc-icon-right-single-arrow:after {
               font-size: 150%;
               margin: 0;
               padding: 0;
            }
         }
    }
    button {
        background: none;
        border: none;
        box-shadow: none;
        color: #7f7f7f;
        font-weight: bold;
        text-transform: capitalize;
        &.fc-state-active {
            color: $text-dark;
        }
    }
    .page-summary {
        clear: both;
        font-size: 14px;
        font-weight: normal;
        margin-left: 0;
        line-height: 0.5em;
        margin-bottom: 10px;
        font-style: italic;
    }
}

th.fc-day-header {
    background: #ededed;
    color: $text-dark;
    padding: 8px 0;
    text-transform: uppercase;
}

.fc-event {
    background: none;
    border: none;
    color: $violet;
    padding: 3px 10px;
    &:hover {
        color: darken( $violet, 30% );
    }
    &.is-active {
        // font-weight: bold;
        // limit this to the filmlinc calendar
        .calendar & {
            color: $black !important;
        }
    }
}

.fc-event {
    padding: 3px 20px;

    // differentiate past events from upcoming
    &.fc-event-past {
        color: #ababab;
    }
    &.fc-not-start,
    &.fc-not-en  {
        background-color: #f5f5f5;
        color: $violet;
        font-weight: bold;
    }
}

.fc-event-container[colspan] a {
    background: darken( #f5f5f5, 5% );
    color: $violet;
    font-weight: bold;
}

// remove highlight for today
.fc-unthemed {
    .fc-day,
    .fc-today {
        background: #fafafa;
    }

    // differentiate past days
    .fc-cell-past {
        background: #f5f5f5;
    }
}

// multi-day events
.fc-not-end,
.fc-not-start {
    padding: 3px 20px !important;
    &.fc-event-past {
        background: darken( #f5f5f5, 10% ) !important;
        color: #ababab !important;
    }
}

// hide event time
.fc-time {
    display: none !important;
}

.fc-day-number {
    padding: 10px 10px 0 0 !important;
}

// NYFF-specific
.calendar-nyff {
    th.fc-day-header {
        background: #222222;
        color: #fff;
    }
    .fc-time {
        display: inline !important;
    }
    &.fc-unthemed {
        th, td {
            border-color: #2d2d2d;
        }
        .fc-day,
        .fc-today {
            background: #242424;
        }
    }
    .fc-day-grid-event {
        opacity: 1;
        transition: opacity 0.3s;
    }
    .co-content h3 {
        color: $black;
    }
}

.nyff-calendar-cont {

    h1 {
        color: $white;
    }

    select {
        color: $gray-text-dark;
    }

    &.is-filtered {
        .fc-day-grid-event {
            opacity: 0.05;
            &.is-active {
                opacity: 1;
            }
        }
    }
    .is-active-popup {
        font-weight: bold;
    }
    .fc-day-grid-event .fc-content {
        white-space: normal;
    }
}

// calendar overlay popup
.fc-week {
    &.has-active-event {
        z-index: 2;
    }
    &.item-left {
        .co {
            left: auto;
            right: 100%;
        }
    }
    &.item-nudged-vertically {
        .co {
            transform: translateY( -50% );
        }
    }
}
.fc-event-container {
    position: relative;
    .co {
        display: block;
        position: absolute;
        top: 0;
        left: 100%;
        z-index: 50;
        .child-item-fixed & {
            position: fixed;
        }
    }
}

.co {
    background: #f5f5f5;
    box-shadow: 0 0 25px 0 transparentize( #000, 0.85 );
    display: none;
    width: 450px;
    img {
        width: 100%;
        display: block;
    }
}

.co-content {
    padding: 20px;
    h2 {
        font-size: 22px;
        line-height: 26px;
        margin: 0 0 10px;
        a {
            color: $gray-dark;
        }
    }
    h3 {
        margin-bottom: 0;
        font-size: 16px;
        line-height: 20px;
        font-weight: bold;
    }
    p {
        font-size: 14px;
        line-height: 18px;
    }
}

// spell out the ul's row layout (needed for some phones)
.co-showtimes-list {
    @include sm-and-down {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 4px;
    }
}

.co-showtimes-list a {
    &:not([href]){ // gray out past links
        color: $gray-medium;
        &:hover {
            text-decoration: none;
        }
    }
}

.co-showtimes-list li {
    display: inline-block;
    margin-right: 5px;
}

.co-showtimes-list li:after {
    content: ','
}

.co-showtimes-list li:last-child:after {
    content: '';
}

.co-container {
    position: relative;
}
.co-closer {
    background: transparentize( #f5f5f5, 0.1 ) !important;
    color: $black;
    position: absolute;
    right: 0;
    text-align: center;
    top: 0;
    transition: background 0.3s;
    z-index: 5;
    line-height: 1;
    padding: 4px;
    font-size: 20px;
    font-weight: bold;

    &:hover {
        background: transparentize( #fff, 0.1 );
        text-decoration: none;
    }
}

.nyff-calendar-cont {
    header {
        overflow: hidden;
        h1 {
            color: #fff;
            float: left;
            width: 25%;
        }
    }
    .calendar-filters {
        float: right;
        width: 75%;
    }
    a[data-section="main-slate"] {
        color: #6699ff;
    }
    a[data-section="special-events"] {
        color: #6c47ff;
    }
    a[data-section="revivals"] {
        color: #00d29f;
    }
    a[data-section="spotlight-on-documentary"] {
        color: #47cdff;
    }
    a[data-section="retrospective"] {
        color: #ed3446;
    }
    a[data-section="convergence"] {
        color: #ff9f1c;
    }
    a[data-section="projections"] {
        color: #ff733f;
    }
    a[data-section="talks"] {
        color: #ff0090;
    }
}

.calendar-filters {
    display: flex;
    font-weight: bold;
    .filter-col-1 {
        flex: 1;
        &:first-of-type {
            flex: 0.5;
            p {
                color: $gray-text-medium;
            }
        }
    }
    .filter-col-3 {
        flex: 3;
        ul {
            columns: 3;
        }
    }
    p {
        color: #fff;
        margin: 0 0 0.5em;
    }
    a.filter-remover {
        display: none;
        .is-filtered & {
            display: block;
        }
    }
    span.filter-remover {
        cursor: pointer;
        margin-left: 10px;
    }

    a {
        text-decoration: none;
    }
}



//
// Mobile Display
//

@include xs {

    .calendar-date > h3 {
        margin-bottom: 40px;
    }

	.co-content {
		border-bottom: 1px solid $gray;
		padding: 0 0 20px 0;
		margin-bottom: 20px;
	}
}
