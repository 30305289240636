$section-map: (
    main-slate: #00aeef,
    convergence: #ff9933,
    projections: #ff6633,
    special-events: #9933cc,
    currents: #ff9933,
    revivals: #00cccc,
    retrospective: #ff0066,
    shorts-programs: #df2ca5,
    spotlight: #ff0066,
    talks: #ff0099
);

$section-color: '';
@mixin section-loop {
    @each $section, $section-color in $section-map {
        $section-color: #{$section-color} !global;
        &[data-section="#{$section}"] {
            @content;
        }
    }
}
