.day-picker__cont {
    position: relative;
    overflow: hidden;
    width: 100%;
    @include sm-and-down {
        z-index: 2;
    }

}

.day-picker {
    background: #000;

    border-bottom: none;
    box-sizing: border-box;
    text-transform: uppercase;
    text-align: center;
    display: flex;
    justify-content: space-between;
    position: relative;
    transition: transform $transition-duration;
    &__pager {
        display: none;
    }
    &__day {
        cursor: pointer;
        display: block;
        padding: 10px;
        font-size: 16px;
        position: relative;
        transition: background-color $transition-duration;
        width: 100%;
        letter-spacing: 0.1em;
        span {
            display: block;
        }
        &--name {
            color: #fff;
            font-weight: bold;
        }
        &--date {
            font-weight: 100;
            color: #cccccc;
            display: none;
        }
        &:after {
            border-left: 15px solid transparent;
            border-right: 15px solid transparent;
            border-top: 10px solid #000;
            content: '';
            height: 0;
            left: calc( 50% - 15px );
            position: absolute;
            top: 100%;
            opacity: 0;
            transition: border-top $transition-duration, opacity $transition-duration;
            width: 0;
            z-index: $zindex-dayArrow;
        }
        &:hover {
            background: transparentize($color-festival, 0.7);
        }
        &.is-active {
            background: $color-festival;

            &:after {
                border-top: 10px solid $color-festival;
                opacity: 1;
            }
            .day-picker__day--date {
                // Jared, I couldn't figure out the right way to set this class.
                color: #fff;
            }
        }
    }
    @include sm-and-down {
        display: block;
        border: none;
        &__day {
            display: inline-block;
            width: 54px;
            &--name {
                font-size: 13px;
                line-height: 13px;
            }
            &--date {
                font-size: 13px;
            }
        }
        &__pager {
            background: #000;
            color: #fff;
            cursor: pointer;
            height: 100%;
            position: absolute;
            top: 0px;
            width: 25px;
            z-index: 5;
            display: flex;
            align-items: center;
            justify-content: center;
            &--prev {
                left: -1px;
                padding: 0 30px 0 10px;
                /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#000000+55,000000+100&1+55,0+100 */
                background: -moz-linear-gradient(left,  rgba(0,0,0,1) 55%, rgba(0,0,0,0) 100%); /* FF3.6-15 */
                background: -webkit-linear-gradient(left,  rgba(0,0,0,1) 55%,rgba(0,0,0,0) 100%); /* Chrome10-25,Safari5.1-6 */
                background: linear-gradient(to right,  rgba(0,0,0,1) 55%,rgba(0,0,0,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
                filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#000000', endColorstr='#00000000',GradientType=1 ); /* IE6-9 */
            }
            &--next {
                right: -1px;
                padding: 0 10px 0 30px;
                /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#000000+0,000000+45&0+0,1+45 */
                background: -moz-linear-gradient(left,  rgba(0,0,0,0) 0%, rgba(0,0,0,1) 45%); /* FF3.6-15 */
                background: -webkit-linear-gradient(left,  rgba(0,0,0,0) 0%,rgba(0,0,0,1) 45%); /* Chrome10-25,Safari5.1-6 */
                background: linear-gradient(to right,  rgba(0,0,0,0) 0%,rgba(0,0,0,1) 45%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
                filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#000000',GradientType=1 ); /* IE6-9 */

            }
        }
    }
}

.day-picker__day {
    border-top: 1px solid $border-color;
}
