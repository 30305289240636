.snippet-blog {
    margin-bottom: 20px;
    &:last-child {
        margin-bottom: 0;
    }

    .blog-image {
        position: relative;
        display: block;

        img {
            width: 100%;
        }

        i {
            position: absolute;
            top: 0;
            right: 0;
            background: $white;
            padding: 3px;
            color: $violet;
        }
    }

    .blog-text {
        a {
            color: $gray-text-dark;
        }
        .stack-link {
            font-size: 22px;
            line-height: 26px;
        }
        @include sm-and-up {
            // when stacked to the right of an image (sm-and-up)
            // bring closer
            margin-left: -1 * $grid-gutter-width / 2;
        }
    }

    &.no-image {
        .blog-icon-only {
            float: left;
            width: 30px;
        }
        .blog-text {
            margin-left: 30px;
        }
    }
}
