body {
    background-color: $off-white;

    &,
    p {
        color: $gray-text-dark;
    }
}


//
// Responsive image by default
//
img {
    height: auto;
    max-width: 100%;
}

ul {
    list-style-type: none;
    padding: 0;
}

//todo(yuvilio) -- what is a module
// //a section is a white box with box-shadow
// section {
//     background-color: $white;
//     @extend %module;
//
//     //to allow for some elements to stretch full width, the padding will only
//     //be done on direct .sinner children
//     >.inner {
//         @extend %section-padding;
//     }
// }

.content-box {
    background-color: $white;
    @extend %shadowed;
    @include xs {
        margin-bottom: 30px;
        padding-bottom: 30px;
    }
}

.stuck {
  position:fixed;
  top:0;
}

// HR
.no-space {
    margin: 0;
    padding: 0;
}


.ads {
    @include sm {
        margin-top: 30px;
    }
}